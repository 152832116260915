/* eslint-disable camelcase */
import IterableTracker from 'services/Iterable';
import sha256 from 'crypto-js/sha256';
import geoIp from 'paymentApi/geoIp';
import { trackEvent } from 'services/Gtm/functions';
import CustomWindow from 'interfaces/Utils';
import { Tinypass } from 'services/Piano/entities/Tinypass';
import { Subscriber } from 'services/Subscriber';
import {
  AccessResponse,
  ConversionResponse,
  CheckoutCustomEvent,
  CustomEvent,
  ExperienceEvent,
  LoginDataProps,
  ResponseVariables,
  TermDetailsProps,
} from './entities/PianoCallbackParams';

declare let window: CustomWindow;

const pianoHandlers = (
  tp: Tinypass,
  setPaywall30: React.Dispatch<React.SetStateAction<string>>,
  handleUserChange: (refreshExperience?: boolean) => void,
) => ({
  checkoutClose: (event: CheckoutCustomEvent) => {
    const { analytics } = window;
    switch (event.state) {
      case 'close':
        analytics.track('form abandoned', {
          form_name: 'Paywall',
        });
        break;
      default:
        break;
    }
    window.tp.experience.execute();
  },
  checkoutComplete: async (conversion: ConversionResponse) => {
    const { analytics, pvid } = window;
    const { chargeAmount, chargeCurrency, termId, uid } = conversion;

    analytics.track('product.purchased', {
      cart_currency: chargeCurrency,
      cart_turnovertaxfree: chargeAmount,
      label: 'Checkout Completed',
      pageview_id: pvid,
      piano_user_id: uid,
      product_id: termId,
    });

    if (tp.user.isUserValid()) {
      const subscriber = new Subscriber();
      await subscriber.init();
      subscriber.getIterableCampaign();
      const { email, iterableEmail, campaignId, templateId = null } = subscriber;

      if (iterableEmail && campaignId) {
        const iterableTracker = new IterableTracker(email);
        iterableTracker
          .trackCheckoutComplete(email, templateId, campaignId, conversion)
          .catch((error) => console.error(error));
      }
    }
  },
  checkoutCustomEvent: (event: CheckoutCustomEvent) => {
    const { analytics, pvid } = window;
    switch (event.eventName) {
      case 'payment-method-selected':
        analytics.track('cart.payment', {
          label: 'Payment Method Selected',
          pageview_id: pvid,
          payment_mode: event.params.method,
        });
        break;
      default:
        break;
    }
  },
  checkoutSelectTerm: (termDetails: TermDetailsProps) => {
    if (tp.user.isUserValid()) {
      const { email } = tp.pianoId.getUser();

      const iterableTracker = new IterableTracker(email);

      iterableTracker
        .trackStartCheckout(window.location.href, termDetails.termName)
        .catch((error) => console.error(error));
    }
  },
  customEvent: (event: CustomEvent) => {
    switch (event.eventName) {
      case 'passwordlessClose':
        window.location.reload();
        break;
      default:
        break;
    }
  },
  experienceExecute: (event: ExperienceEvent) => {
    const { countryCode } = event.result.events[0].eventExecutionContext;
    tp.pianoId.init({ stage: `countryCode-${countryCode}` });
    tp.setCustomVariable('countryCode', countryCode);
  },
  loginSuccess: async (loginData: LoginDataProps) => {
    const trackLogin = () => {
      const { analytics, pvid } = window;
      const { uid: pianoUID, email } = tp.pianoId.getUser();

      const trackingData = {
        eventAction: '',
        eventLabel: '',
      };

      if (loginData.registration) {
        geoIp(pianoUID);
        analytics.track(
          'account.created',
          {
            email,
            pageview_id: pvid,
          },
          {
            context: {
              traits: {
                email,
                piano_user_id: pianoUID,
              },
            },
          },
        );
        trackingData.eventAction = 'create account';
        trackingData.eventLabel = 'account creation successful';
      } else {
        trackingData.eventAction = 'sign in';
        trackingData.eventLabel = 'sign in successful';
      }

      trackEvent({
        ...trackingData,
        eventCategory: 'account',
        userIDCD: sha256(email).toString(),
      });
    };
    const rid: string = process.env.PIANO_RID_DIGITAL || 'default';
    const accessResponse: AccessResponse = await new Promise((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      window.tp.api.callApi('/access/check', { rid }, (data: AccessResponse) => {
        if (data) {
          resolve(data);
        } else {
          reject(new Error('Check access failed'));
        }
      });
    });

    // Don't refresh experience if registration login, as this will prevent user from getting
    // Registration/Day Pass term through regwall. The required refresh will happen after the user closes the checkout
    // modal.
    const refreshExperience =
      !loginData.registration &&
      (accessResponse.access.granted || (tp.contentSection === 'F-Service' && loginData.source !== 'OFFER'));

    handleUserChange(refreshExperience);
    trackLogin();
  },
  manualCreditRedeemed: () => {
    tp.template.close();
    window.location.reload();
  },
  setResponseVariable: ({ responseVariables: { paywall30 } }: ResponseVariables) => {
    setPaywall30(paywall30);
  },
});

export default pianoHandlers;
