import { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { PageType } from 'interfaces/content/articles/Post';
import { PlacementName } from 'interfaces/components/Ad';
import {
  InhouseAdDerivedTrackingData,
  InhouseAdProps,
  InhouseAdType,
  TrackableInhouseAd,
} from 'interfaces/components/InhouseAd';
import getPersonalizedAds, { PersonalizedAds } from 'lib/personalizationApi/getPersonalizedAds';
import { toSnakeCase } from 'utils/stringUtils';

interface PersonalizationProviderProps {
  children: JSX.Element;
}

export interface PersonalizationContextInterface {
  ads: PersonalizedAds;
  persistedAdsLoaded: boolean;
  getAdForSlot: (pageType: PageType, placement: PlacementName, index: number) => TrackableInhouseAd | null;
}

export const PersonalizationContext = createContext<PersonalizationContextInterface>({
  ads: {},
  getAdForSlot: () => null,
  persistedAdsLoaded: false,
});

const usePersonalizationContext = () => {
  const [ads, setAds] = useState<PersonalizedAds>({});
  const [persistedAdsLoaded, setPersistedAdsLoaded] = useState(false);
  // we want to fetch ads once per page; because SPA is disabled right now, we can do it like this
  // after enabling SPA, we will need to listen for router changes & trigger another fetch each time
  const fetchedAdsLoaded = useRef(false);
  const fetchingAds = useRef(false);

  useEffect(() => {
    // load personalized ad content from local storage; UI will render ads based on persisted content, not on live req
    try {
      const { ads: storedAds } = JSON.parse(localStorage.getItem('personalized-ads') || '{}') as {
        ads: PersonalizedAds;
      };
      if (storedAds) {
        setAds(storedAds);
      }
    } finally {
      setPersistedAdsLoaded(true);
    }
  }, []);

  const performAdUpdates = useCallback((pageType?: string) => {
    // only for testing purposes, on dev env, an use case can be manually set by adding it in url and send it as query param to the API
    const isDevelopment = process.env.ENVIRONMENT !== 'prod';
    const urlParams = new URLSearchParams(window.location.search);
    const ucParamsValue = isDevelopment ? urlParams.get('uc') : null;

    // call personalization api to retrieve newer personalized ad content
    getPersonalizedAds(pageType, ucParamsValue)
      .then((fetchedAds: PersonalizedAds) => {
        if (fetchedAds) {
          localStorage.setItem('personalized-ads', JSON.stringify({ ads: fetchedAds }));
          // update ad slot if page requests personalized inhouse ads
          setAds(fetchedAds);
        }
      })
      .finally(() => {
        fetchingAds.current = false;
      });
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isAdSlotPersonalized = (pageType: PageType, placement: PlacementName, index: number) => {
    const hasAdDefined =
      ads[pageType] &&
      ads[pageType][placement] &&
      ads[pageType][placement][index] &&
      typeof ads[pageType][placement][index] === 'object' &&
      'placementId' in ads[pageType][placement][index] &&
      'adId' in ads[pageType][placement][index] &&
      'cta' in ads[pageType][placement][index] &&
      'name' in ads[pageType][placement][index] &&
      'adFormats' in ads[pageType][placement][index] &&
      typeof ads[pageType][placement][index].adFormats === 'object' &&
      'desktop' in ads[pageType][placement][index].adFormats &&
      'mobile' in ads[pageType][placement][index].adFormats &&
      typeof ads[pageType][placement][index].adFormats.desktop === 'object' &&
      typeof ads[pageType][placement][index].adFormats.mobile === 'object' &&
      'imageUrl' in ads[pageType][placement][index].adFormats.desktop &&
      'imageHeight' in ads[pageType][placement][index].adFormats.desktop &&
      'imageWidth' in ads[pageType][placement][index].adFormats.desktop &&
      'imageUrl' in ads[pageType][placement][index].adFormats.mobile &&
      'imageHeight' in ads[pageType][placement][index].adFormats.mobile &&
      'imageWidth' in ads[pageType][placement][index].adFormats.mobile;
    return hasAdDefined;
  };

  const getAdForSlot = useCallback(
    (pageType: PageType, placement: PlacementName, index: number) => {
      let ad: InhouseAdProps | null = null;
      let trackableAd: TrackableInhouseAd | null = null;

      if (isAdSlotPersonalized(pageType, placement, index)) {
        ad = ads[pageType][placement][index];
      }
      // start async call that updates personalized ad content
      // frontend will not wait for this response to render UI
      // a lazy-refresh will be performed, meaning that content for personalized ads will be one step behind real data
      if (!fetchedAdsLoaded.current && !fetchingAds.current) {
        fetchingAds.current = true;
        fetchedAdsLoaded.current = true;
        performAdUpdates(pageType);
      }

      // Derive tracking data and return enhanced ad object
      if (ad) {
        const ctaUrl = new URL(ad.cta);
        const detailedPlacement = toSnakeCase(placement + index.toString());
        const campaign = ctaUrl.searchParams.get('itm_campaign');
        const variant = ctaUrl.searchParams.get('itm_content');
        // HTML ads not supported at this point
        const type = InhouseAdType.Native;
        const targetUrl = ctaUrl.href.replace(ctaUrl.search, '');

        const trackingData: InhouseAdDerivedTrackingData = {
          campaign,
          detailedPlacement,
          targetUrl,
          type,
          variant,
        };

        trackableAd = {
          ...ad,
          tracking: trackingData,
        };
      }

      return trackableAd;
    },
    [ads, isAdSlotPersonalized, performAdUpdates],
  );

  return {
    ads,
    getAdForSlot,
    persistedAdsLoaded,
  };
};

/* eslint-disable react/prop-types */
export const PersonalizationProvider: React.FC<PersonalizationProviderProps> = ({ children }) => {
  const personalizationContextValue = usePersonalizationContext();
  return (
    <PersonalizationContext.Provider value={personalizationContextValue}>{children}</PersonalizationContext.Provider>
  );
};
