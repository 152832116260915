/* eslint-disable sort-keys-fix/sort-keys-fix */
import { Microsites } from 'constants/constants';

export const brandColors = {
  [Microsites.Own]: {
    // Brand
    brandPrimary: '#EFF8FA',
    brandSecondary: '#ED1C24',
    // Accent
    accentPrimary: '#007B9D',
    // Surfaces
    surfaceBrand: '#EFF8FA',
    // Text
    textAccentPrimary: '#007B9D',
    // Border
    borderAccentPrimary: '#007B9D',
    // Icon
    iconAccentPrimary: '#007B9D',
    // Shades - Brand Primary
    shadeBrandPrimary900: '#68BCCF',
    shadeBrandPrimary700: '#A3E0EE',
    shadeBrandPrimary500: '#C1EAF3',
    shadeBrandPrimary200: '#DAF1F6',
    shadeBrandPrimary100: '#F1F9FB',
    shadeBrandPrimary50: '#F0F8F9',
    // Shades - Brand Secondary
    shadeBrandSecondary900: '#6F0E12',
    shadeBrandSecondary700: '#AD3D41',
    shadeBrandSecondary500: '#E3585E',
    shadeBrandSecondary200: '#F9C6C8',
    shadeBrandSecondary100: '#FCF0F1',
  },
  [Microsites.Recommends]: {
    // Brand
    brandPrimary: '#008F6A',
    brandSecondary: '#FFBC22',
    // Accent
    accentPrimary: '#008F6A',
    // Surfaces
    surfaceBrand: '#008F6A',
    // Text
    textAccentPrimary: '#008F6A',
    // Border
    borderAccentPrimary: '#008F6A',
    // Icon
    iconAccentPrimary: '#008F6A',
    // Shades - Brand Primary
    shadeBrandPrimary900: '#015D45',
    shadeBrandPrimary700: '#008F6A',
    shadeBrandPrimary500: '#2ED6AB',
    shadeBrandPrimary200: '#B8F1E2',
    shadeBrandPrimary100: '#E6FAF5',
    shadeBrandPrimary50: '#F2FCF9',
    // Shades - Brand Secondary
    shadeBrandSecondary900: '#8F680C',
    shadeBrandSecondary700: '#B28418',
    shadeBrandSecondary500: '#DAB563',
    shadeBrandSecondary200: '#FFECC1',
    shadeBrandSecondary100: '#FFF8E9',
  },
  [Microsites.Crypto]: {
    // Brand
    brandPrimary: '#877329',
    brandSecondary: '#000082',
    // Accent
    accentPrimary: '#877329',
    // Surfaces
    surfaceBrand: '#877329',
    // Text
    textAccentPrimary: '#877329',
    // Border
    borderAccentPrimary: '#877329',
    // Icon
    iconAccentPrimary: '#877329',
    // Shades - Brand Primary
    shadeBrandPrimary900: '#534718',
    shadeBrandPrimary700: '#837028',
    shadeBrandPrimary500: '#9F8F54',
    shadeBrandPrimary200: '#DBD5BF',
    shadeBrandPrimary100: '#F3F1EA',
    shadeBrandPrimary50: '#F1F0EC',
    // Shades - Brand Secondary
    shadeBrandSecondary900: '#272787',
    shadeBrandSecondary700: '#5050C0',
    shadeBrandSecondary500: '#7474D0',
    shadeBrandSecondary200: '#B8B8DC',
    shadeBrandSecondary100: '#E6E6F3',
  },
  [Microsites.Well]: {
    // Brand
    brandPrimary: '#2E3DFF',
    brandSecondary: '#00AEEF',
    // Accent
    accentPrimary: '#2E3DFF',
    // Surfaces
    surfaceBrand: '#2E3DFF',
    // Text
    textAccentPrimary: '#2E3DFF',
    // Border
    borderAccentPrimary: '#2E3DFF',
    // Icon
    iconAccentPrimary: '#2E3DFF',
    // Shades - Brand Primary
    shadeBrandPrimary900: '#151D7C',
    shadeBrandPrimary700: '#212CB6',
    shadeBrandPrimary500: '#5460FF',
    shadeBrandPrimary200: '#C4C9FF',
    shadeBrandPrimary100: '#F2F3FF',
    shadeBrandPrimary50: '#F0F8F9',
    // Shades - Brand Secondary
    shadeBrandSecondary900: '#0480AE',
    shadeBrandSecondary700: '#5AADCD',
    shadeBrandSecondary500: '#7AC6E3',
    shadeBrandSecondary200: '#B9E1F0',
    shadeBrandSecondary100: '#F0FBFF',
  },
};

export const commonColors = {
  // Accent
  accentSecondary: '#ED1C24',
  // Neutrals
  neutralBlack: '#111111',
  neutralSoftBlack: '#252525',
  neutralDarkGray: '#666666',
  neutralLightGray: '#FEFEFE',
  neutralLightGray1: '#F9F9F9',
  neutralLightGray2: '#F2F2F2',
  neutralLightGray3: '#D1D1D6',
  neutralWhite: '#FFFFFF',
  // Other/Semantics
  semanticSucces: '#32A6A6',
  semanticsWarning: '#FFBC22',
  semanticsError: '#ED1C24',
  // Background
  backgroundPrimary: '#FEFEFE',
  backgroundSecondary: '#F9F9F9',
  backgroundTertiary: '#111111',
  // Surfaces
  surfacePrimary: '#F9F9F9',
  surfaceSecondary: '#F2F2F2',
  surfaceTertiary: '#D1D1D6',
  surfaceQuartenary: '#666666',
  surfaceWhite: '#FFFFFF',
  surfaceBlack: '#111111',
  // Text
  textPrimary: '#111111',
  textSecondary: '#666666',
  textTertiary: '#D1D1D6',
  textAccentSecondary: '#ED1C24',
  textWhite: '#FFFFFF',
  textLink: '#007B9D',
  // Border
  borderPrimary: '#F2F2F2',
  borderSecondary: '#D1D1D6',
  borderTertiary: '#666666',
  borderAccentSecondary: '#ED1C24',
  borderBlack: '#111111',
  borderGold: '#988647',
  // Icon
  iconPrimary: '#111111',
  iconSecondary: '#666666',
  iconTertiary: '#D1D1D6',
  iconAccentSecondary: '#ED1C24',
  iconWhite: '#FFFFFF',
  // Shades - Accents
  shadeAccentPrimary900: '#6F0E12',
  shadeAccentPrimary700: '#AD3D41',
  shadeAccentPrimary500: '#E3585E',
  shadeAccentPrimary200: '#F9C6C8',
  shadeAccentPrimary100: '#FCF0F1',
  // Shades - Semantics Success
  shadeSemanticSuccess900: '#397575',
  shadeSemanticSuccess700: '#5E9999',
  shadeSemanticSuccess500: '#6CBBBB',
  shadeSemanticSuccess200: '#A6DBDB',
  shadeSemanticSuccess100: '#E4F9F9',
  // Shades - Semantics Warning
  shadeSemanticWarning900: '#D29C1E',
  shadeSemanticWarning700: '#EBB63C',
  shadeSemanticWarning500: '#F5CF7A',
  shadeSemanticWarning200: '#FFE8B5',
  shadeSemanticWarning100: '#FFF8E9',
  // Shades - Semantics Error
  shadeSemanticError900: '#6F0E12',
  shadeSemanticError700: '#AD3D41',
  shadeSemanticError500: '#E3585E',
  shadeSemanticError200: '#F9C6C8',
  shadeSemanticError100: '#FCF0F1',
};

export type CommonColors = {
  [key in keyof typeof commonColors]?: string;
};

export type BrandColors = {
  [key in keyof (typeof brandColors)[(typeof Microsites)[keyof typeof Microsites]]]?: string;
};
