import { useState, useRef, useMemo, JSXElementConstructor, ReactElement, AllHTMLAttributes } from 'react';
import { Disclosure } from 'api/MicrositeConfig/getMicrositeConfig';
import RawHtml from 'utils/miscUtils/rawHtml';
import { Close } from 'icons/Close';
import useClickOutside from 'hooks/useClickOutside';
import { Microsites } from 'constants/constants';
import {
  Wrapper,
  ButtonAsLink,
  Dropdown,
  Container,
  DropdownHeader,
  DropdownContainer,
} from './DisclosureStripeStyles';

export interface DisclosureStripeProps {
  content: Disclosure;
  templateType?: string;
  subDomain?: string;
  className?: string;
}

const DisclosureStripe = ({ content, templateType, subDomain, className }: DisclosureStripeProps) => {
  const landingPageClass = templateType === 'landing_page' ? 'landingPage' : '';
  const cryptoDisclosureClass = Microsites.Crypto === subDomain ? 'crypto' : '';
  const [showFullText, setShowFullText] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  useClickOutside(dropdownRef, () => setShowFullText(false));

  const { shortText, fullText } = content;

  const fullTextExists = fullText !== '';

  const updatedFullText = useMemo(() => {
    const fullTextElements = RawHtml({ html: fullText });

    if (Array.isArray(fullTextElements)) {
      return fullTextElements.map((elem: ReactElement<any, string | JSXElementConstructor<any>>) => {
        const elemProps = elem.props as AllHTMLAttributes<ReactElement>;
        if (elemProps && elemProps.href) {
          return {
            ...elem,
            props: { ...elemProps, rel: 'noopener noreferrer', role: 'link', target: '_blank' },
          };
        }
        return elem;
      });
    }
    return fullTextElements;
  }, [fullText]);

  const showFullDisclosure = () => {
    setShowFullText(true);
  };

  const hideFullDisclosure = () => {
    setShowFullText(false);
  };

  return (
    <Container className={`${landingPageClass} ${cryptoDisclosureClass} ${className}`}>
      <Wrapper>
        {RawHtml({ html: shortText })}{' '}
        <DropdownContainer ref={dropdownRef}>
          {fullTextExists && <ButtonAsLink onClick={showFullDisclosure}> Learn more. </ButtonAsLink>}
          {showFullText && (
            <Dropdown>
              <DropdownHeader>
                <button
                  type='button'
                  className='close'
                  aria-label='Hide Disclosure'
                  onClick={hideFullDisclosure}
                  onKeyDown={(e) => {
                    if (e.key !== 'Tab') {
                      hideFullDisclosure();
                    }
                  }}
                >
                  <Close />
                </button>
              </DropdownHeader>
              <div
                role='presentation'
                onKeyDown={(e) => {
                  if (e.key !== 'Tab' && e.key !== 'Enter') {
                    hideFullDisclosure();
                  }
                }}
              >
                {updatedFullText}
              </div>
            </Dropdown>
          )}
        </DropdownContainer>
      </Wrapper>
    </Container>
  );
};

export default DisclosureStripe;
