import CustomLink from 'components/Globals/Base/Link';
import styled, { css } from 'styled-components';
import { breakpoint, color } from 'styles/globals';
import { getColor, Theme } from 'styles/theme';

const recommendsNavigationWrapperV1 = css`
  &.recommends, &.education {
    margin: 24px auto 0;
    @media (${breakpoint.mdMin}) {
      margin: 32px auto 8px;
    }
    @media (${breakpoint.lgMin}) {
      margin: 48px auto 0;
    }
  }
`;

const recommendsNavigationWrapperV2 = css<{ theme: Theme; $isSimple: boolean }>`
  &.recommends, &.education {
    position: relative;
    margin: 0 auto;
    min-height: 63px;
    box-shadow: 0px 10px 10px 0px ${({ $isSimple }) => ($isSimple ? '' : getColor('textPrimary'))}0D;

    @media (${breakpoint.lgMax}) {
      display: none;
    }
  }
`;

const recommendsNavigationItemV2 = css`
  &.recommends, &.education {
    position: static;

    &:hover > ul, &:focus-within > ul {
      display: grid;
    }  
  }
`;

const recommendsStyledLinkV2 = css<{ theme: Theme }>`
  &.recommends, &.education {
    display: table-cell;
    vertical-align: middle;
    height: 63px;
    &:hover {
      color: ${getColor('textAccentPrimary')};
    }  
    &.selected {
      font-size: 24px;
    }
  }
`;

export const NavigationWrapper = styled.nav<{
  theme: Theme;
  $isSimple: boolean;
  $showNewTopicNav: boolean;
}>`
  display: flex;
  justify-content: ${(props) => (props.$isSimple ? 'flex-end' : 'center')};
  min-height: 28px;
  margin: 24px auto;
  position: ${(props) => (props.$isSimple ? '' : 'relative')};
  flex-grow: 1;

  &.homepage.europe, &.homepage.asia {
    margin: 16px auto;
  }

  @media (${breakpoint.mdMin}) {
    min-height: 32px;
    padding: ${(props) => (props.$isSimple ? '0 24px 0 0' : '0 24px')};
    margin: 32px auto;

    &.homepage {
      margin: 32px auto 56px auto;
    }

    &.homepage.europe, &.homepage.asia {
      margin: 24px auto;
    }
  }

  @media (${breakpoint.lgMin}) {
    min-height: 44px;
    margin: 48px auto;
  }

  @media (${breakpoint.xlMin}) {
    padding: 0px 48px;

    &.homepage {
      margin: 48px auto 72px;
    }
    &.homepage.europe, &.homepage.asia {
      margin: 48px auto;
    }
  }
    
  ${(props) => (props.$showNewTopicNav ? recommendsNavigationWrapperV2 : recommendsNavigationWrapperV1)}
`;

export const NavigationList = styled.ul<{ $isSimple: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  padding-left: 16px;
  white-space: nowrap;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (${breakpoint.mdMin}) {
    padding: ${(props) => (props.$isSimple ? '0' : '0 24px')};
  }

  @media (${breakpoint.mdMax}) {
    overflow-x: scroll;
    overflow-y: hidden;
  }

  @media (${breakpoint.xlMin}) {
    max-width: 1440px;
    padding: 0px 48px;
  }
`;

export const NavigationItem = styled.li<{ $showNewTopicNav: boolean }>`
  position: relative;
  display: inline-block;
  
  &:last-child {
    a {
      margin-right: 8px;
    }
  }

  @media (${breakpoint.mdMin}) {
    &:last-child {
      a {
        margin-right: 0;
      }
    }
  }

  &:hover, &:focus-within {
    > ul {
      display: flex;  
    }
  }

  ${(props) => props.$showNewTopicNav && recommendsNavigationItemV2}
`;

export const NavigationStyledLink = styled(CustomLink)<{
  theme: Theme;
  $color: string;
  $showNewTopicNav: boolean;
  $isSimple: boolean;
}>`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  transition: all 0.3s ease;
  padding: 0 8px;

  @media (${breakpoint.mdMin}) {
    padding: 0 12px;
  }
  @media (${breakpoint.lgMin}) {
    font-size: ${(props) => (props.$isSimple ? '16px' : '20px')};
    line-height: 27px;
  }
  @media (${breakpoint.xxlMin}) {
    padding: 0 24px;
  }

  &.showSubmenu {
    display: flex;
    align-items: center;

    & > svg {
      stroke: ${color.darkGray};
      height: 20px;
      width: 20px;
      margin-left: 8px;
      transform: rotate(90deg);
    }

    &:hover > svg {
      stroke: ${color.linkBlue};
      transform: rotate(270deg);
    }
  }

  &.selected {
    color: ${color.primaryBlack};
    font-size: 20px;
    line-height: 27px; 
    text-decoration: underline;
    text-decoration-color: ${(props) => props.$color};
    text-underline-offset: 6px;
    text-decoration-thickness: 2px;

    &:hover {
      color: ${color.linkBlue};
    }

    @media (${breakpoint.mmMin}) {
      font-size: 24px;
      line-height: 32px;
      text-underline-offset: 8px;
    }
    @media (${breakpoint.lgMin}) {
      font-size: 32px;
      line-height: 43px;
      text-underline-offset: 10px;
    }
  }
    
  ${(props) => props.$showNewTopicNav && recommendsStyledLinkV2}
`;
