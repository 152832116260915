export const bkptVal: { [key: string]: number } = {
  // 1152px
  hybrid: 72,

  // 1024px
  lg: 64,

  // 768px (for Header subscribe button)
  md: 48,

  // 512px
  mm: 32,

  sm: 20, // 320px
  xl: 80, // 1280px
  xxl: 90, // 1440px
};

export const breakpoint: { [key: string]: string } = Object.keys(bkptVal).reduce<Record<string, string>>(
  (acc, curr) => {
    acc[`${curr}Min`] = `min-width: ${bkptVal[curr]}rem`;
    acc[`${curr}Max`] = `max-width: ${bkptVal[curr] - 0.05}rem`;
    acc[`${curr}Val`] = `${bkptVal[curr]}rem`;
    return acc;
  },
  {},
);

export enum GridSpacing {
  sm = 16,
  md = 24,
  lg = 24,
  hybrid = 24,
  xl = 48,
  xxl = 48,
}

/**
 * Creates a formula for a given number of columns, spacings and a given grid spacing.
 * The calculation is as follows: The screen has 2 spacings (margin-left and margin-right).
 * The remaining screen has 12 columns with 11 spacings in between. This leads to a total of 12 columns
 * and 13 spacings. The spacing has a fixed with of **gridSpacing**. The columns width is dynamic.
 * We then conclude that:
 *  100vh / 12 = (12 col + 13 sp) / 12 = 1 col + 1 sp + 1/12 sp
 */
export const grid = (cols: number, spacings: number, gridSpacing: GridSpacing): string => {
  const percent = (100 / 12) * cols;
  const includedSpacing = cols + cols / 12;
  const spacingDiff = Math.round((spacings - includedSpacing) * gridSpacing);

  return `calc(${percent}vw ${spacingDiff < 0 ? '-' : '+'} ${Math.abs(spacingDiff)}px)`;
};

export const color = {
  connectDarkCyan: '#1B9C9E',
  cryptoBlue: '#000082',
  cryptoCrashCourseGreen: '#00FF73',
  cryptoCrashCourseYellowText: '#7C4B00',
  cryptoGold: '#877329',
  cryptoRecommendsYellow: '#FFFC02',
  cryptoRed: '#FF0064',
  darkGray: '#666666',
  dataStoreGrayishMagenta: '#976EA1',
  errorDarkRed: '#a01117',
  eventsCream: '#F5F2E4',
  fortuneDailyBackground: '#F2F0E9',
  fortuneTealTint100: '#E6F2F5',
  globalWhite: '#FEFEFE',
  lightBlue: '#eff8fa',
  lightGray: '#f2f2f2',
  lightRed: '#FCF0F1',
  linkBlue: '#007b9d',
  medBlue: '#c2d6e0',
  myAccountGold: '#988647',
  myAccountSuccess: '#39A6A5',
  navigationGray: '#8E8E93',
  newsletterBackground: '#f3f1ea',
  newsletterDots: '#DBD5BF',
  newsletterLine: '#9f8f54',
  newslettersYellow: '#FFBD21',
  premiumYellow: '#e6b31e',
  primaryBlack: '#111111',
  primaryBlue: '#E3F3F1',
  primaryRed: '#E31E23',
  primaryWhite: '#FFFFFF',
  recommendsDarkGreen: '#008F6A',
  recommendsDarkerGreen: '#00654B',
  recommendsGreen: '#00CD98',
  recommendsLightGreen: '#E6FAF5',
  recommendsLightOrange: '#FFBC22',
  recommendsMediumGreen: '#B8F1E2',
  recommendsOrange: '#FF5414',
  recommendsPaleOrange: '#FFF8E9',
  secondaryDarkGray: '#2D2D2E',
  secondaryGreen: '#257D53',
  secondaryLightGray: '#D1D1D6',
  softBlack: '#252525',
  successSituation: '#32a6a6',
  textError: '#ED1C24',
  wellAetnaPurple: '#7D3F98',
  wellBlue: '#2E3DFF',
  wellLightBlue: '#00AEEF',
  wellPink: '#FF9999',
};

export const font = {
  georgia: '\'Georgia\', Times New Roman, Playfair Display, Georgia, Serif',
  graphikCompact: '\'Graphik Compact\', Arial Narrow, Helvetica neue Condensed, sans-serif',
  graphikCond: '\'Graphik Condensed\', Arial Narrow, Helvetica neue Condensed, sans-serif',
  saolText: '\'Saol Text\', Times New Roman, Playfair Display, Georgia, Serif',
};

/**
 * Aligns the content with the Header and Footer, overflowing the available space coming from the Layout parent.
 * @param marginTop - string value representing the margin-top; this will remain consistent on all resolutions
 * @param marginBottom - string value representing the margin-bottom; this will remain consistent on all resolutions
 */
export const overflowLayout = (marginTop: string, marginBottom: string) => `
  margin: ${marginTop} auto ${marginBottom};
  max-width: ${breakpoint.xxlVal};

  @media (${breakpoint.mdMin}) {
      margin: ${marginTop} -24px ${marginBottom};
      width: calc(100% + 48px);
  }
  
  @media (${breakpoint.xlMin}) {
      margin: ${marginTop} -48px ${marginBottom};
      width: calc(100% + 96px);
  }
`;
