interface InhouseAdFormat {
  imageId: number;
  imageUrl: string;
  imageWidth: number;
  imageHeight: number;
}

interface InhouseAdFormats {
  desktop: InhouseAdFormat;
  mobile: InhouseAdFormat;
}

export enum InhouseAdType {
  Native = 'native',
  Html = 'html',
}

export interface InhouseAdDerivedTrackingData {
  detailedPlacement: string;
  campaign: string | null;
  variant: string | null;
  type: InhouseAdType;
  targetUrl: string;
}

export interface InhouseAdProps {
  name: string;
  placementId: string;
  adId: string;
  cta: string;
  adFormats: InhouseAdFormats;
}

export interface TrackableInhouseAd extends InhouseAdProps {
  tracking: InhouseAdDerivedTrackingData;
}
