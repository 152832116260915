import styled, { css } from 'styled-components';
import { breakpoint } from 'styles/globals';
import { Theme } from 'styles/theme';
import { BreakpointValues, FontFamilyAndSize, FontSizes } from 'styles/theme/types';
import orderBreakpoints from 'utils/miscUtils/orderBreakpoints';

type Props = FontFamilyAndSize & { theme: Theme };

export const Heading = styled.h2<Props>`
  ${({ theme, $size, $fontFamily }) => {
    const { defaultHeadingStyles, headingFonts } = theme.typography.headings;
    const fontFamily = headingFonts[$fontFamily];
    const fontFamilyCustomStyles = fontFamily?.custom;

    const { default: defaultSize, ...customSizes } = $size || {};
    const breakpoints = orderBreakpoints(customSizes);

    return css`
      ${defaultHeadingStyles}
      ${fontFamilyCustomStyles}
      font-size: ${fontFamily?.sizes[defaultSize]};

      ${Object.keys(breakpoints).map(
    (bkp: string) => css`
          @media (${breakpoint[bkp]}) {
            font-size: ${fontFamily?.sizes[breakpoints[bkp as BreakpointValues] as keyof FontSizes]};
          }
      `,
  )}
    `;
  }};
`;

export default Heading;
