/* eslint-disable no-shadow */

/*
 other values supported; the only values applicable to Fortune Premium are the ones below
 */
export enum DataProtectionOptions {
  EU = 'implied,eu',
  US = 'implied,us',
  NON_EU_NON_US = 'none',
}

export enum GDPRConsentOptions {
  REQUIRED = 'permit 1 required',
  REQUIRED_FUNCTIONAL = 'permit 1,2 functional',
  REQUIRED_ADVERTISING = 'permit 1,3',
  ALL = 'permit 1,2,3',
}

/* full documentation here:
 https://github.com/InteractiveAdvertisingBureau/USPrivacy/blob/master/CCPA/US%20Privacy%20String.md

 other values supported; the only values applicable to Fortune Premium are the ones below
 */

export enum CCPAConsentOptions {
  OPT_IN = '1YNN',
  OPT_OUT = '1YYN',
  // not applicable means user did not click on any banner option yet
  NOT_APPLICABLE = '1---',
}

export type DataProtection = DataProtectionOptions.EU | DataProtectionOptions.US | DataProtectionOptions.NON_EU_NON_US;

export type GDPRConsent = GDPRConsentOptions.REQUIRED | GDPRConsentOptions.REQUIRED_FUNCTIONAL | GDPRConsentOptions.ALL;

export type CCPAConsent = CCPAConsentOptions.OPT_IN | CCPAConsentOptions.OPT_OUT | CCPAConsentOptions.NOT_APPLICABLE;
