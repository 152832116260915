import React from 'react';
import AdSlot from './AdSlot';
import { FooterAd } from './Styles';

interface FooterProps {
  id: number;
}

const Footer: React.FC<FooterProps> = ({ id }: FooterProps) => (
  <FooterAd>
    <AdSlot
      placementName='Footer'
      index={id + 1}
      slotId={`Footer${id}`}
    />
  </FooterAd>
);

export default Footer;
