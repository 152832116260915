/* eslint-disable no-debugger */
import React, { useState } from 'react';
import Script from 'next/script';
import { PageAdConfig } from 'interfaces/components/Ad';
import { AdContext, useAdContext } from 'services/Ad';
import CustomWindow from 'interfaces/Utils';
import { calculateBrowserWidth } from 'services/Ad/utils/browser';
import calcPubmaticUrl from 'services/Ad/utils/pubmatic';

interface AdManagerProps {
  children: JSX.Element;
  pageAdConfig: PageAdConfig;
}

declare let window: CustomWindow;

const AdManager: React.FC<AdManagerProps> = ({ children, pageAdConfig }) => {
  const [googletagInitialized, setGoogletagInitialized] = useState(false);
  const adContextValue = useAdContext(pageAdConfig, googletagInitialized);

  const pubmaticUrl = calcPubmaticUrl(162221);

  const CLPcheck =
    typeof window !== 'undefined' &&
    (window.location.search.includes('prx_t') || window.location.search.includes('ntv_adpz'));

  return (
    <>
      <Script
        id='pubmatic-js'
        src={pubmaticUrl}
        strategy='afterInteractive'
      />
      <Script
        id='google-tag'
        src='https://securepubads.g.doubleclick.net/tag/js/gpt.js'
        strategy='afterInteractive'
        onLoad={() => {
          setGoogletagInitialized(true);
          googletag.cmd.push(() => {
            let stickyFlag = true;

            let visibleSlots: googletag.Slot[] = [];

            googletag.pubads().addEventListener('slotVisibilityChanged', (event) => {
              const { slot } = event;

              const slotIsNotIncluded = visibleSlots.every(
                (visibleSlot) => visibleSlot.getSlotElementId() !== slot.getSlotElementId(),
              );

              if (event.inViewPercentage >= 80 && slotIsNotIncluded) {
                visibleSlots.push(slot);
              } else if (event.inViewPercentage < 80 && !slotIsNotIncluded) {
                const removeSlotId = slot.getSlotElementId();

                visibleSlots = visibleSlots.filter((visibleSlot) => visibleSlot.getSlotElementId() !== removeSlotId);
              }
            });

            function refreshVisibleSlots() {
              googletag.pubads().refresh(visibleSlots);
              window.setTimeout(refreshVisibleSlots, 30000);
            }

            refreshVisibleSlots();

            googletag.pubads().setTargeting('host', window.location.hostname);
            googletag.pubads().addEventListener('slotRequested', () => {
              if (document.getElementById('AdvancedSearch_InStream0')?.childNodes.length === 0) {
                googletag
                  .defineSlot(
                    '/21809533738/fortune/search/advancedsearch/instream',
                    [
                      [728, 90],
                      [300, 250],
                      [336, 280],
                    ],
                    'AdvancedSearch_InStream0',
                  )
                  ?.addService(googletag.pubads());
                googletag.pubads().refresh();
              }
            });

            googletag.pubads().addEventListener('slotRenderEnded', (e: googletag.events.SlotRenderEndedEvent) => {
              const { slot } = e;
              const SlotId = slot.getSlotElementId();

              // @ts-ignore: Object is possibly 'null'.
              const height = e.size[1];
              // @ts-ignore: Object is possibly 'null'.
              const width = e.size[0];

              const ClientWidth = calculateBrowserWidth();

              const CheckCrown =
                ((width === 970 && height === 90) || (width === 300 && height === 50)) &&
                document.querySelectorAll('iframe[class^="cc-sbillboard"]').length > 0;

              if (SlotId === 'Leaderboard0' && !CheckCrown) {
                const root = document.querySelector(':root') as HTMLElement;

                if (stickyFlag) root.style.setProperty('--ld-height', `${height}px`);

                const parentLeader = document.getElementById('Leaderboard0')?.parentNode as HTMLElement;
                const leaderBoard = document.getElementById('Leaderboard0') as HTMLElement;

                parentLeader.setAttribute('style', 'position: sticky; z-index: 100;');
                leaderBoard.setAttribute('style', 'position: sticky;');

                if (ClientWidth < 576) {
                  document.addEventListener('scroll', () => {
                    parentLeader.setAttribute('style', 'position: sticky; top: -10px; z-index: 9999;');
                    leaderBoard.setAttribute('style', 'height: 70px; position: sticky; z-index: 9999;');
                  });
                }

                if (stickyFlag && ClientWidth >= 576) {
                  root.style.setProperty('--ld-height', `${height}px`);
                  parentLeader.setAttribute(
                    'style',
                    `min-height: ${height}px; position: sticky; top: 0px; z-index: 100;`,
                  );
                  leaderBoard.setAttribute('style', `min-height: ${height}px;  position: sticky;`);

                  setTimeout(() => {
                    parentLeader.setAttribute('style', '');
                    leaderBoard.setAttribute('style', '');
                    root.style.setProperty('--ld-height', '0px');

                    stickyFlag = false;

                    if (document.getElementsByClassName('rr-sticky-hub')[0]) {
                      document
                        .getElementsByClassName('rr-sticky-hub')[0]
                        .setAttribute(
                          'style',
                          'min-height: 250px; position: sticky; top: 80px; width: 350px; margin-bottom: 36px;',
                        );
                    }
                  }, 2500);
                }
              } else if (document.getElementsByClassName('rr-sticky-hub')[0]) {
                document
                  .getElementsByClassName('rr-sticky-hub')[0]
                  .setAttribute(
                    'style',
                    'min-height: 250px; position: sticky; top: 200px; width: 350px; margin-bottom: 36px;',
                  );
              }
            });
          });

          // Nativo targeting
          const kvpMap: { [key: string]: string } = {};
          kvpMap.topics = pageAdConfig.topics ? pageAdConfig.topics.join(',') : '';
          window.ntvConfig = window.ntvConfig || {};
          window.ntvConfig.keyValues = kvpMap;
        }}
      />
      {CLPcheck && (
        <Script
          id='nativo-iframe'
          src='https://assets.promotedarticle.com/iframeInjection.js'
        />
      )}
      <Script
        id='nativo-js'
        src='https://s.ntv.io/serve/load.js'
        strategy='afterInteractive'
        data-ntv-set-no-auto-start
        {...(CLPcheck && { 'data-ntv-pub-injection': true })}
        onLoad={() => {
          if (typeof window !== 'undefined' && window.location.search.includes('prx_t')) window.PostRelease.Start(); // Run Nativo ads brand-studio pages
        }}
      />
      <Script id='amazon-ads-tag'>
        {
          '!function(a9,a,p,s,t,A,g){if(a[a9])return;function q(c,r){a[a9]._Q.push([c,r])}a[a9]={init:function(){q("i",arguments)},fetchBids:function() {q("f",arguments)},setDisplayBids:function(){},targetingKeys:function(){return[]},dpa:function(){q("di",arguments)},rpa:function(){q("ri",arguments)},upa:function(){q("ui",arguments)},_Q:[]};}("apstag",window);'
        }
      </Script>
      <AdContext.Provider value={adContextValue}>{children}</AdContext.Provider>
    </>
  );
};

export default AdManager;
