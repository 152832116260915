import { Author } from 'interfaces/content/Authors';
import { MediaItem } from 'interfaces/content/Media';
import { PostSettings } from 'interfaces/content/articles/PostSettings';
import { Faq } from 'interfaces/components/Faq';
import { BypassPaywallResponse } from 'interfaces/BypassPaywall';

export interface PremiumCategory {
  name: string;
  premiumCategoryId: number;
}

export interface PrimaryPostTag {
  name: string;
  primaryPostTagId: number;
  link: string;
  uri: string;
}

export interface PrimarySection {
  name: string;
  primarySectionId: number;
  link: string;
  uri: string;
}

interface PrimaryTopic {
  name: string;
  primaryTopicId: number;
}

export interface Section {
  name: string;
  sectionId: number;
  slug: string;
}

export enum EduPageType {
  CHILD_RANKING_LIST = 'edu-child-rankings-list',
  RANKING_LIST = 'edu-rankings-list',
  SCHOOL = 'edu-school',
  CATEGORY = 'edu-category',
  SECTION = 'section',
  ARTICLE = 'article',
}

// TODO: use enums FECM-950
export type PageType =
  | ''
  | 'article'
  | 'article-v2'
  | 'longform'
  | 'company'
  | 'franchise-list'
  | 'franchise-list-search'
  | 'franchise-list-item'
  | 'franchise-list-item-company'
  | 'franchise'
  | 'page'
  | 'mainHomepage'
  | 'homepage'
  | 'section'
  | 'sponsored-page'
  | 'static-page'
  | 'topic'
  | 'tag'
  | 'magazine'
  | 'package'
  | 'author'
  | 'rankings'
  | 'guide hub'
  | 'guide'
  | 'evergreen'
  | 'latest'
  | 'person'
  | EduPageType;

/**
 * This properties are expected on every page component
 * to support integrations
 */
export interface Page {
  adTags: { adtagname: string; description: string; slug: string }[];
  authors: Author[];
  authorNames: Array<string>;
  canonicalUrl: string;
  channel: string;
  databaseId: number;
  dateGmt: string;
  description: string;
  faq: Faq[];
  image: MediaItem | string | null;
  imageTwitter?: MediaItem | string | null;
  keywords: string;
  link: string;
  metaTitle: string;
  modifiedGmt: string;
  pageId?: number;
  pageType: PageType;
  premiumCategory: PremiumCategory;
  primarySection: PrimarySection;
  socialDescription: string;
  socialTitle: string;
  sectionNames: Array<string>;
  sections: Section[];
  tagNames: Array<string>;
  tags: {
    name: string;
    tagId: number;
  }[];
  thumbnailUrl: string;
  title: string;
  titlePrefix?: string;
  topicNames: Array<string>;
  topics: { name: string; slug: string }[];
  url: string;
  platformCD: string;
  featuredMediaType?: string;
  relatedVideoType?: string;
  widgetCss?: string | null;
  prefixedDatabaseId?: string | null;
}

/**
 * This properties are specific to Longform & Article
 * posts
 */
export interface Post extends Page {
  audienceDek: string;
  bypassPaywall: BypassPaywallResponse;
  content: string;
  dek: string;
  id: string;
  postId: number;
  postSettings: PostSettings;
  primaryPostTag: PrimaryPostTag;
  primaryTopic: PrimaryTopic;
  showModifiedTimestamp: boolean;
}
export interface Person {
  education: string;
  image: string;
  name: string;
  position: string;
}

export interface PersonPage extends Page {
  person: Person;
}
