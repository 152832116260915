import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import DrawerMenu from 'components/DrawerMenu';
import Menu from 'components/Header/Menu';
import NavigationBar from 'components/NavigationBar';
import TopicNavigation from 'components/TopicBasedNavigation';
import { MenuItemProps } from 'interfaces/navigation/Header';
import { Microsites, topicNavBorderColor } from 'constants/constants';
import DisclosureStripe from 'components/DisclosureStripe';
import { Disclosure } from 'api/MicrositeConfig/getMicrositeConfig';
import BinanceTickerBar from 'components/BinanceTickerBar';
import TradingViewTicker from 'components/TradingViewTicker';
import ExpiredSubAlert from 'components/ExpiredSubAlert';
import Notice from 'components/Notice';
import { NoticeContext } from 'providers/NoticeProvider';
import useLockBodyScroll from 'hooks/useLockBodyScroll';
import { DrawerToggle } from './HeaderStyles';

export interface HeaderProps {
  subDomain: string;
  domainLogo: string | null;
  topics: MenuItemProps[];
  disclosure?: Disclosure;
  templateType?: string;
  leftMenu: MenuItemProps[];
  rightMenu: MenuItemProps[];
}

const Header = ({ subDomain, domainLogo, topics, leftMenu, rightMenu, disclosure, templateType }: HeaderProps) => {
  const { asPath } = useRouter();
  const showTradingViewWidget = asPath === '/section/finance/';
  const isEducation = Microsites.Education === subDomain;
  const isRecommends = Microsites.Recommends === subDomain;
  const showNewTopicNav = isRecommends || isEducation;

  const hamburgerButtonRef = useRef<HTMLLabelElement>(null);
  const menuToggleRef = useRef<HTMLInputElement>(null);
  const closeButtonRef = useRef<HTMLDivElement>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { notice, noticeVisible } = useContext(NoticeContext) || {};

  const handleMenuToggle = () => {
    if (menuToggleRef.current) {
      const newState = !menuToggleRef.current.checked;
      menuToggleRef.current.checked = newState;
      setIsDrawerOpen(newState);
      if (newState) {
        setTimeout(() => {
          closeButtonRef.current?.focus();
        }, 200);
      } else {
        setTimeout(() => {
          hamburgerButtonRef.current?.focus();
        }, 200);
      }
    }
  };

  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    if ((e.key === 'Enter' || e.key === ' ') && hamburgerButtonRef.current) {
      e.preventDefault();
      handleMenuToggle();
    } else if (e.key === 'Escape' && menuToggleRef.current) {
      menuToggleRef.current.checked = false;
      setIsDrawerOpen(false);
      setTimeout(() => {
        hamburgerButtonRef.current?.focus();
      }, 500);
    }
  }, []);

  const handleClickOutside = useCallback((e: MouseEvent) => {
    if (closeButtonRef.current && !closeButtonRef.current.contains(e.target as Node)) {
      if (menuToggleRef.current) {
        menuToggleRef.current.checked = false;
        setIsDrawerOpen(false);
      }
    }
  }, []);

  const trapFocus = useCallback(
    (e: FocusEvent) => {
      if (isDrawerOpen && closeButtonRef.current && !closeButtonRef.current.contains(e.target as Node)) {
        e.preventDefault();
        e.stopPropagation();

        const focusableElements = closeButtonRef.current.querySelectorAll('a, [tabindex]:not([tabindex="-1"])');
        const firstFocusableElement = focusableElements[0] as HTMLElement;
        const lastFocusableElement = focusableElements[focusableElements.length - 1] as HTMLElement;

        if (e.target === firstFocusableElement) {
          lastFocusableElement.focus();
        } else {
          firstFocusableElement.focus();
        }
      }
    },
    [isDrawerOpen],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('focusin', trapFocus);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('focusin', trapFocus);
    };
  }, [handleKeyDown, handleClickOutside, trapFocus]);

  useLockBodyScroll(isDrawerOpen);

  const disclosureProps = disclosure ?
    {
      content: disclosure,
      subDomain,
      templateType,
    } :
    undefined;

  return (
    <>
      <DrawerToggle
        type='checkbox'
        id='drawer-toggle'
        className='drawer-toggle'
        ref={menuToggleRef}
        aria-label='Toggle Menu'
        onChange={(e) => setIsDrawerOpen(e.target.checked)}
      />
      <NavigationBar
        id='header-wrapper-id'
        subDomain={subDomain}
        domainLogo={domainLogo}
        topics={topics}
        showNewTopicNav={showNewTopicNav}
        disclosureProps={subDomain === Microsites.Crypto ? undefined : disclosureProps}
        ref={hamburgerButtonRef}
      />
      {showTradingViewWidget && <TradingViewTicker />}
      {noticeVisible ? (
        <Notice
          type={notice?.type}
          message={notice?.message}
          visible={noticeVisible}
        />
      ) : null}
      <ExpiredSubAlert />
      {disclosureProps && !showNewTopicNav && <DisclosureStripe {...disclosureProps} />}
      {Microsites.Crypto === subDomain && <BinanceTickerBar />}
      {!showNewTopicNav && (
        <TopicNavigation
          subdomain={subDomain}
          color={topicNavBorderColor[subDomain]}
          topics={topics}
          isSimple={false}
        />
      )}
      <DrawerMenu
        subDomain={subDomain}
        ref={closeButtonRef}
        handleClick={() => setIsDrawerOpen(menuToggleRef.current?.checked ?? false)}
        keyboardToggle={handleMenuToggle}
      >
        <Menu
          currentUrl={asPath}
          leftMenu={leftMenu}
          rightMenu={rightMenu}
          subDomain={subDomain}
          onSearchClick={handleMenuToggle}
          onKeyDown={handleMenuToggle}
        />
      </DrawerMenu>
    </>
  );
};

export default Header;
