import Footer, { FooterProps } from 'components/Footer';
import { HeaderNav } from 'interfaces/navigation/Header';
import Header from 'components/Header';
import { LeaderboardAdWrapper, LayoutWrapper, NativoLeaderboard } from 'components/Layout/LayoutStyles';
import { Leaderboard } from 'components/Ad/AdSlot';

interface LayoutProps {
  children: JSX.Element;
  headerNav: HeaderNav;
  leaderboard: boolean;
  footer: FooterProps;
  hasVanityUrl: boolean | undefined;
}

const Layout = (props: LayoutProps) => {
  const { children, leaderboard, footer, headerNav, hasVanityUrl } = props;

  return (
    <>
      {leaderboard && (
        <LeaderboardAdWrapper>
          <Leaderboard id={0} />
        </LeaderboardAdWrapper>
      )}
      {hasVanityUrl && <NativoLeaderboard className='nativo-content__leaderboard' />}
      {headerNav && (
        <Header
          {...headerNav}
          topics={headerNav.topicNavigation}
        />
      )}
      <LayoutWrapper $microsite={headerNav?.subDomain}>
        <main>{children}</main>
        {footer && <Footer {...footer} />}
      </LayoutWrapper>
    </>
  );
};

export default Layout;
