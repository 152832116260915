import styled, { css } from 'styled-components';
import { breakpoint } from 'styles/globals';
import { Theme, getColor } from 'styles/theme';
import { Microsites } from 'constants/constants';

export const LeaderboardAdWrapper = styled.div<{ theme: Theme }>`
  background-color: ${getColor('surfaceWhite')};
  display: block;
  transition: transform 300ms ease-in-out;
  width: 100%;
  text-align: center;

  @media (${breakpoint.lgMin}) {
    display: flex;
  }

  @media (${breakpoint.mdMax}) {
    height: 70px;
  }  
`;

export const NativoLeaderboard = styled.div`
  min-height: 74px;

  @media (${breakpoint.mdMin}) {
    min-height: 114px;
  }
`;

export const LayoutWrapper = styled.div<{ theme: Theme; $microsite: string }>`
  margin: auto;
  overflow: clip;

  // overwriting the old layout pading with the new design system values (only for recommends)
  ${({ $microsite }) =>
    ([Microsites.Recommends, Microsites.Education].includes($microsite) ?
      css`
        padding: 0 var(--Grid-Margin);
      ` :
      css`
        @media (${breakpoint.mdMin}) {
          padding: 0px 24px;
        }

        @media (${breakpoint.xlMin}) {
          padding: 0px 48px;
        }
      `)}

  @media (${breakpoint.xxlMin}) {
    max-width: 1440px;
  }
`;
