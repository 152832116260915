import { PageAdConfig, AdConfig, AdSlotConfig, AdTargetingConfig, AdPlacementConfig } from 'interfaces/components/Ad';
import adConfig from 'services/Ad/config';
import {
  createDefaultPath,
  createInstreamPath,
  createLeaderboardPath,
  createRightRailPath,
  createRightRailArticlePath,
} from './createPath';

/**
 * Build ad targeting object from pageAdConfig. The logic is to take a predefined list of fields,
 *  remove any that are null, and make all values of the object an array of strings.
 */
const buildTargeting = (pageAdConfig: PageAdConfig, pageView: number): AdTargetingConfig => {
  const { ctype, cid, ch, tags, topics, premiumCategory, loginStatus } = pageAdConfig;

  // Set up key/val targeting, filter out anything empty.
  const keyVals: { [key: string]: string | string[] | null } = {
    ch,
    cid,
    ctype,
    host: window.location.hostname,
    login_status: loginStatus || 'unknown',
    premiumCategory,
    pv: `${pageView}`,
    tags,
    topics,
  };

  // Remove null values
  const filteredKeyVals = Object.fromEntries(Object.entries(keyVals).filter(([, val]) => val !== null));

  // Convert everything to array
  const targeting = Object.keys(filteredKeyVals).reduce((acc, key) => {
    const value = keyVals[key] as string | string[];
    acc[key] = Array.isArray(value) ? value : [value];
    return acc;
  }, {} as { [key: string]: string[] });

  return targeting;
};

const buildInitialSlots = (pageAdConfig: PageAdConfig): AdSlotConfig[] =>
  pageAdConfig.slots.map((slot) => {
    const config = {
      id: slot.id,
      index: slot.index,
      path:
        slot.placementName === 'Leaderboard' ?
          createLeaderboardPath(pageAdConfig) :
          createDefaultPath(pageAdConfig, slot.placementName),
      placementName: slot.placementName,
    };
    return config;
  });

const parsedPlacements = (pageAdConfig: PageAdConfig): AdPlacementConfig[] =>
  adConfig.placements.map((placement) => {
    if (
      placement.name === 'InStream' ||
      placement.name === 'InContent' ||
      placement.name === 'SimpleInStream' ||
      placement.name === 'Homepage-InStream'
    ) {
      return {
        ...placement,
        path: createInstreamPath(pageAdConfig, placement.name),
        refreshRate: adConfig.refreshRate,
      };
    }
    if (placement.name === 'RightRailFlex') {
      return {
        ...placement,
        path: createRightRailPath(pageAdConfig, placement.name),
        refreshRate: adConfig.refreshRate,
      };
    }
    if (placement.name === 'RightRailFlex_Articles') {
      return {
        ...placement,
        path: createRightRailArticlePath(pageAdConfig, placement.name),
        refreshRate: adConfig.refreshRate,
      };
    }

    return {
      ...placement,
      path: createDefaultPath(pageAdConfig, placement.name),
      refreshRate: adConfig.refreshRate,
    };
  });

export default function buildConfig(pageAdConfig: PageAdConfig, pageView: number, adConsent: boolean): AdConfig {
  const { defaultPath } = pageAdConfig;

  const config = {
    ...adConfig,
    nonPersonalAds: !adConsent,
    path: defaultPath,
    placements: parsedPlacements(pageAdConfig),
    slots: buildInitialSlots(pageAdConfig),
    targeting: buildTargeting(pageAdConfig, pageView),
  };

  return config;
}
