import CustomWindow from 'interfaces/Utils';
import { Tinypass } from './entities/Tinypass';
import { PianoPageConfig } from './entities/PianoPageConfig';
import pianoHandlers from './pianoHandlers';
import passwordReset from './passwordReset';

export * from './setPianoConfig';
export * from 'services/Subscriber';

declare let window: CustomWindow;

/**
 * Run to initalize Piano
 * See: https://docs.piano.io/track/implementing-piano#implementsinglepage
 *
 * @param pianoPageConfig
 */
export function pianoInit(
  pianoPageConfig: PianoPageConfig,
  isBypassed: boolean,
  setPaywall30: React.Dispatch<React.SetStateAction<string>>,
  handleUserChange: () => void,
) {
  let tp = pianoSetSiteData(setPaywall30, handleUserChange);
  tp = pianoSetPageData(tp, pianoPageConfig, isBypassed);

  tp.push([
    'init',
    () => {
      // tp methods are called within the init callback
      passwordReset(tp);
      tp.pianoId.init();
      tp.experience.init();
    },
  ]);
}

/**
 * Run only on history change
 * @param pianoPageConfig
 */
// export function pianoRunExperience(pianoPageConfig: PianoPageConfig) {
//   let tp = window.tp || [];
//   tp = pianoSetPageData(tp, pianoPageConfig);
//   tp.experience.execute();
// }

/**
 * Handles pushing data into @tp object prior to the loading of the
 * Piano Tinypass library and the initialization. No @tp methods
 * shoudld be called here.
 */
export function pianoSetSiteData(
  setPaywall30: React.Dispatch<React.SetStateAction<string>>,
  handleUserChange: () => void,
) {
  const tp = window.tp || [];
  const domain = window.location.hostname;
  tp.push(['setAid', process.env.PIANO_AID]);
  tp.push(['setCxenseSiteId', process.env.PIANO_SITE_ID]);
  tp.push(['setSandbox', JSON.parse(process.env.PIANO_SANDBOX?.toLowerCase() || '')]);
  tp.push(['setCookieDomain', domain]);
  tp.push(['setUsePianoIdUserProvider', true]);
  tp.push(['setUseTinypassAccounts', false]);
  tp.push(['setApplePayMerchantId', process.env.APPLE_MERCHANT_ID]);
  tp.push(['setDebug', process.env.PIANO_SANDBOX]);

  const handlers = pianoHandlers(tp, setPaywall30, handleUserChange);
  (Object.keys(handlers) as Array<keyof typeof handlers>).forEach((name) => {
    tp.push(['addHandler', name, handlers[name]]);
  });

  return tp;
}

export function pianoSetPageData(
  tp: Tinypass,
  { contentSection, tags, publishDate }: PianoPageConfig,
  isBypassed: boolean = false,
) {
  const section = isBypassed ? 'Editorial Bypass' : contentSection;
  tp.push(['setContentSection', section]);
  tp.push(['setContentCreated', publishDate]);
  tp.push(['setPageURL', window.location.href]);
  tp.push(['setTags', tags]);
  // Postlaunch TODO:
  // tp.push(['setCustomVariable', 'contentType', channel]); // might be useful for bucketing page types

  return tp;
}

export function pianoMyAccount() {
  window.tp.myaccount.show({
    containerSelector: '.my-account-payment',
    displayMode: 'inline',
  });
}
