/* eslint-disable jsx-a11y/alt-text */
import Image, { ImageProps } from 'next/legacy/image';
import NextImage, { ImageProps as NextImageProps } from 'next/image';
import { BLURRED_GRAY_PLACEHOLDER } from 'constants/constants';

const getPathFromUrl = (url: string) => url.split('?')[0];

const customLoader = ({ src, width, quality }: { src: string; width: number; quality?: number }) =>
  `${getPathFromUrl(src)}?w=${width}&q=${quality || 75}`;

/**
 *@deprecated will be removed in the future use NextCustomImage instead for new components
 *@see https://fortune.atlassian.net/browse/FECM-336 TODO: FECM-336
 *@see NextCustomImage
 */
const CustomImage = (props: ImageProps) => {
  const { priority } = props;

  return (
    <Image
      loader={customLoader}
      layout='responsive'
      objectFit='cover'
      placeholder='blur'
      blurDataURL={BLURRED_GRAY_PLACEHOLDER}
      {...props}
      {...(!priority && { loading: 'lazy' })}
    />
  );
};

export default CustomImage;

/**
 * Latest version of NextImage that is responsive with container size
 *
 * Example usage:
 * ```tsx
 *
 * <NextCustomImage
 *      alt='Alt text'
 *      src='https://example.com/image.jpg'
 *      width={200}
 *      height={200}
 *      priority
 *      className='custom-image'
 *      style={{}} //Omit the default container fill behaviour
 *  />
 *  ```
 * @see https://nextjs.org/docs/pages/api-reference/components/image#responsive-images
 * */
export const NextCustomImage = (props: NextImageProps) => {
  const { priority } = props;
  return (
    <NextImage
      loader={customLoader}
      placeholder='blur'
      blurDataURL={BLURRED_GRAY_PLACEHOLDER}
      sizes='100vw'
      style={{
        height: 'auto',
        objectFit: 'cover',
        width: '100%',
      }}
      {...props}
      {...(!priority && { loading: 'lazy' })}
    />
  );
};
