import CustomWindow from 'interfaces/Utils';
import { EVENTS } from './constants';

declare let window: CustomWindow;

interface LoggedInData {
  user: string;
  token: string;
}

const showLogin = (): void => {
  window.tp.pianoId.show({
    disableSignUp: false,
    displayMode: 'modal',
    loggedIn: (data: LoggedInData) => {
      console.info('User: ', data.user, 'login: ', data.token);
      const event = new CustomEvent(`${EVENTS.PIANO_LOGIN}`);
      window.dispatchEvent(event);
    },
    loggedOut: () => {
      console.info('User logged out.');
      window.location.reload();
    },
    screen: 'login',
  });
};

export default showLogin;
