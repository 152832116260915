const COMMON_TEST_IDS = {
  AUTHORS_CONTAINER: 'authors-container',
  AUTHOR_BY_TEXT: 'author-by-text',
  AUTHOR_NAME: 'author-name',
  CRASH_COURSE_TOUT: 'crash-course-tout',
  DATE_CONTAINER: 'date-container',
  DESKTOP_BREADCRUMBS: 'desktop-breadcrumbs',
  FORTUNE_LOGO: 'fortune-logo',
  HERO_IMAGE: 'hero-image',
  HERO_IMAGE_LINK: 'hero-image-link',
  HERO_TITLE_LINK: 'hero-title-link',
  HERO_TITLE_TEXT: 'hero-title-text',
  HOMEPAGE_FEATURED_SECTIONS: 'homepage-featured-sections',
  HOMEPAGE_RIGHT_RAIL: 'homepage-right-rail',
  PINNED_ARTICLES_LIST: 'pinned-articles-list',
  PINNED_ARTICLE_CARD: 'pinned-article-card',
  PINNED_SUBSECTIONS_LIST: 'pinned-subsections-list',
  PINNED_SUBSECTION_CARD: 'pinned-subsections-card',
  RELATED_ARTICLES_RR: 'related-articles-rr',
  REVIEWED_BY_TEXT: 'reviewed-by-text',
  REVIEWER_NAME: 'reviewer-name',
  RIGHT_RAIL_AD: 'right-rail-ad',
  RIGHT_RAIL_STICKY_AD: 'right-rail-sticky-ad',
  RIGHT_RAIL_STICKY_AD_HUB: 'right-rail-sticky-ad-hub-page',
  TABLET_AND_MOBILE_BREADCRUMBS: 'tablet-and-mobile-breadcrumbs',
  THE_LATEST_MODULE: 'the-latest-module',
  TOP_ARTICLES: 'top-articles',
};

export type TestIds = {
  [key in keyof typeof COMMON_TEST_IDS]?: string;
};

const getCommonTestId = (value: keyof TestIds) => COMMON_TEST_IDS[value];

export default getCommonTestId;
