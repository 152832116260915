import { GlobalAdConfig } from 'interfaces/components/Ad';

export const ADS_ID = process.env.GAM_ID || '';
export const ADS_NAME = process.env.GAM_NAME || '';
export const ADS_BASE_PATH = `/${ADS_ID}/${ADS_NAME}`;

const adConfig = {
  amazon: {
    bidTimeout: 2e3,
    enabled: true,
    pubID: '5018',
  },
  enabled: true,
  lazyLoad: {
    enabled: false,
    fetchMarginPercent: 50,
    mobileScaling: 2.0,
    renderMarginPercent: 50,
  },
  nonPersonalAds: true,
  path: ADS_BASE_PATH,
  placements: [
    {
      lazyLoad: true,
      name: 'Leaderboard',
      path: ADS_BASE_PATH,
      sizeMappings: [
        {
          mediaTypes: ['banner'],
          size: [
            [300.0, 50.0],
            [320.0, 50.0],
          ],
          viewport: { size: [0.0, 0.0] },
        },
        {
          mediaTypes: ['banner'],
          size: [
            [728.0, 90.0],
            [970.0, 90.0],
          ],
          viewport: { size: [768.0, 0.0] },
        },
        {
          mediaTypes: ['banner'],
          size: [
            [970.0, 250.0],
            [970.0, 90.0],
            [970.0, 66.0],
            [728.0, 90.0],
          ],
          viewport: { size: [1024.0, 0.0] },
        },
      ],
    },
    {
      name: 'RightRailFlex',
      path: ADS_BASE_PATH,
      sizeMappings: [
        {
          mediaTypes: ['banner'],
          size: [
            [300.0, 50.0],
            [320.0, 50.0],
            [300.0, 250.0],
            [336.0, 280.0],
          ],
          viewport: { size: [0.0, 0.0] },
        },
        {
          mediaTypes: ['banner'],
          size: [[300.0, 250.0]],
          viewport: { size: [1024.0, 0.0] },
        },
      ],
    },
    {
      name: 'SimpleInStream',
      path: ADS_BASE_PATH,
      sizeMappings: [
        {
          mediaTypes: ['banner'],
          size: [
            [300.0, 50.0],
            [320.0, 50.0],
            [300.0, 250.0],
            [336.0, 280.0],
          ],
          viewport: { size: [0.0, 0.0] },
        },
        {
          mediaTypes: ['banner'],
          size: [[728.0, 90.0]],
          viewport: { size: [1152.0, 0.0] },
        },
      ],
    },
    {
      name: 'InStream',
      path: ADS_BASE_PATH,
      sizeMappings: [
        {
          mediaTypes: ['banner'],
          size: [
            [300.0, 50.0],
            [320.0, 50.0],
            [300.0, 250.0],
            [336.0, 280.0],
          ],
          viewport: { size: [0.0, 0.0] },
        },
        {
          mediaTypes: ['banner'],
          size: [
            [320.0, 50.0],
            [468.0, 60.0],
            [300.0, 100.0],
            [320.0, 100.0],
            [300.0, 250.0],
            [336.0, 280.0],
          ],
          viewport: { size: [768.0, 0.0] },
        },
        {
          mediaTypes: ['banner'],
          size: [
            [728.0, 90.0],
            [300.0, 250.0],
            [336.0, 280.0],
          ],
          viewport: { size: [1152.0, 0.0] },
        },
      ],
    },
    {
      name: 'Gallery',
      path: ADS_BASE_PATH,
      sizeMappings: [
        {
          mediaTypes: ['banner'],
          size: [
            [300.0, 250.0],
            [336.0, 280.0],
          ],
          viewport: { size: [0.0, 0.0] },
        },
      ],
    },
    {
      name: 'Footer',
      path: ADS_BASE_PATH,
      sizeMappings: [
        {
          mediaTypes: ['banner'],
          size: [
            [300.0, 50.0],
            [320.0, 50.0],
            [300.0, 250.0],
            [336.0, 280.0],
          ],
          viewport: { size: [0.0, 0.0] },
        },
        {
          mediaTypes: ['banner'],
          size: [
            [728.0, 90.0],
            [300.0, 250.0],
          ],
          viewport: { size: [768.0, 0.0] },
        },
      ],
    },
    {
      name: 'Homepage-InStream',
      path: ADS_BASE_PATH,
      sizeMappings: [
        {
          mediaTypes: ['banner'],
          size: [
            [300.0, 50.0],
            [320.0, 50.0],
            [300.0, 250.0],
            [336.0, 280.0],
          ],
          viewport: { size: [0.0, 0.0] },
        },
        {
          mediaTypes: ['banner'],
          size: [[728.0, 90.0]],
          viewport: { size: [1152.0, 0.0] },
        },
      ],
    },
    {
      name: 'Newsletter-InStream',
      path: ADS_BASE_PATH,
      sizeMappings: [
        {
          mediaTypes: ['banner'],
          size: [
            [300.0, 50.0],
            [320.0, 50.0],
            [300.0, 250.0],
            [336.0, 280.0],
          ],
          viewport: { size: [0.0, 0.0] },
        },
        {
          mediaTypes: ['banner'],
          size: [[728.0, 90.0]],
          viewport: { size: [1152.0, 0.0] },
        },
      ],
    },
    {
      name: 'RightRailFlex_Articles',
      path: ADS_BASE_PATH,
      sizeMappings: [
        {
          mediaTypes: ['banner'],
          size: [],
          viewport: { size: [0.0, 0.0] },
        },
        {
          mediaTypes: ['banner'],
          size: [
            [300.0, 250.0],
            [336.0, 280.0],
            [300.0, 600.0],
            [300.0, 1050.0],
          ],
          viewport: { size: [1024.0, 0.0] },
        },
      ],
    },
    {
      name: 'Footer_Tout',
      path: ADS_BASE_PATH,
      sizeMappings: [
        {
          mediaTypes: ['banner'],
          size: [[300.0, 250.0]],
          viewport: { size: [0.0, 0.0] },
        },
        {
          mediaTypes: ['banner'],
          size: [[600.0, 250.0]],
          viewport: { size: [768.0, 0.0] },
        },
      ],
    },
    {
      name: 'TermArchive-Billboard',
      path: ADS_BASE_PATH,
      sizeMappings: [
        {
          mediaTypes: ['banner'],
          size: [[300.0, 250.0]],
          viewport: { size: [0.0, 0.0] },
        },
        {
          mediaTypes: ['banner'],
          size: [[970.0, 250.0]],
          viewport: { size: [1024.0, 0.0] },
        },
      ],
    },
    {
      name: 'Homepage-SimpleInStream',
      path: ADS_BASE_PATH,
      sizeMappings: [
        {
          mediaTypes: ['banner'],
          size: [[300.0, 250.0]],
          viewport: { size: [0.0, 0.0] },
        },
        {
          mediaTypes: ['banner'],
          size: [[728.0, 90.0]],
          viewport: { size: [768.0, 0.0] },
        },
      ],
    },
    {
      name: 'InContent',
      path: ADS_BASE_PATH,
      sizeMappings: [
        {
          mediaTypes: ['banner'],
          size: [
            [300.0, 50.0],
            [320.0, 50.0],
            [320.0, 100.0],
            [300.0, 250.0],
            [336.0, 280.0],
          ],
          viewport: { size: [0.0, 0.0] },
        },
        {
          mediaTypes: ['banner'],
          size: [
            [320.0, 50.0],
            [468.0, 60.0],
            [300.0, 100.0],
            [320.0, 100.0],
            [300.0, 250.0],
            [336.0, 280.0],
          ],
          viewport: { size: [768.0, 0.0] },
        },
        {
          mediaTypes: ['banner'],
          size: [
            [728.0, 90.0],
            [300.0, 250.0],
            [970.0, 250.0],
            [970.0, 90.0],
          ],
          viewport: { size: [1152.0, 0.0] },
        },
      ],
    },
  ],
  prebid: {
    enabled: false,
  },
  refreshRate: 60,
};

export default adConfig as GlobalAdConfig;
