import { createGlobalStyle } from 'styled-components';

const Fonts = createGlobalStyle`
  /* SAOL TEXT */
  @font-face {
    font-display: optional;
    font-family: 'Saol Text';
    font-style: normal;
    src: url('/fonts/SaolText-Regular.woff2') format('woff');
  }

  @font-face {
    font-display: optional;
    font-family: 'Saol Text';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/SaolText-Bold.woff2') format('woff');
  }

  @font-face {
    font-display: optional;
    font-family: 'Saol Text';
    font-style: normal;
    font-weight: 600;
    src: url('/fonts/SaolText-Semibold.woff2') format('woff');
  }

  /* GRAPHIK COMPACT */
  @font-face {
    font-display: optional;
    font-family: 'Graphik Compact';
    font-weight: 500;
    src: url('/fonts/GraphikCompact-Medium-Web.woff2') format('woff2');
  }

  @font-face {
    font-display: optional;
    font-family: 'Graphik Compact';
    font-weight: 700;
    src: url('/fonts/GraphikCompact-Semibold-Web.woff2') format('woff2');
  }

  @font-face {
    font-display: optional;
    font-family: 'Graphik Compact';
    src: url('/fonts/GraphikCompact-Regular-Web.woff2') format('woff2');
  }

  /* GRAPHIK CONDENSED */
  @font-face {
    font-display: optional;
    font-family: 'Graphik Condensed';
    src: url('/fonts/GraphikCondensed-Semibold-Web.woff2') format('woff2');
  }

  @font-face {
    font-display: optional;
    font-family: 'Graphik Condensed';
    font-weight: 600;
    src: url('/fonts/GraphikCondensed-Semibold-Web.woff2') format('woff2');
  }

  @font-face {
    font-display: optional;
    font-family: 'Graphik Condensed';
    font-weight: 300;
    src: url('/fonts/GraphikCondensed-Light-Web.woff2') format('woff2');
  }

  @font-face {
    font-display: optional;
    font-family: 'Graphik Condensed';
    font-weight: 400;
    src: url('/fonts/GraphikCondensed-Regular-Web.woff2') format('woff2');
  }

  @font-face {
    font-display: optional;
    font-family: 'Graphik Condensed';
    font-weight: 500;
    src: url('/fonts/GraphikCondensed-Medium-Web.woff2') format('woff2');
  }

  @font-face {
    font-display: optional;
    font-family: 'Graphik Condensed';
    font-weight: 600;
    src: url('/fonts/GraphikCondensed-Semibold-Web.woff2') format('woff2');
  }

  @font-face {
    font-display: optional;
    font-family: 'Graphik Condensed';
    font-weight: 700;
    src: url('/fonts/GraphikCondensed-Bold-Web.woff2') format('woff2');
  }
`;

export default Fonts;
