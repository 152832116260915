import { NextCustomImage } from 'components/Image';
import { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { CloseIconWhite } from 'icons/CloseIconWhite';
import { useRouter } from 'next/router';
import { useAdTag } from 'services/Ad';
import useBreakpoint from 'hooks/useBreakpoint';
import { useAdvertisingConsent } from 'hooks/useAdvertisingConsent';
import { NoConsent, CloseIconContainer, CloseButton, IFrame, Container, Wrapper } from './VodVideoStyles';

export interface VodVideoProps {
  videoId: string;
  disableAutoplay?: boolean;
  className?: string;
  dataCy?: string;
}

const VodVideo = ({ videoId, disableAutoplay = false, className = '', dataCy = '' }: VodVideoProps) => {
  const router = useRouter();
  const isLgMin = useBreakpoint('lgMin');
  const adTag = useAdTag(router.asPath);
  const consent = useAdvertisingConsent();

  const [play, setPlay] = useState(false);
  const [dockingMode, setDockingMode] = useState(false);
  const [disableDockingMode, setDisableDockingMode] = useState(false);
  const [videoContainerRef, inViewPort] = useInView({ threshold: 0.5 });

  const videoUrl = `https://fortune.com/videos/embed/${videoId}?autoplay=${!disableAutoplay}&adTagUrl=${encodeURIComponent(
    adTag,
  )}`;

  useEffect(() => {
    if (!play && !disableDockingMode) {
      const timer = setTimeout(() => {
        if (inViewPort) {
          setPlay(!disableAutoplay);
        }
      }, 1000);
      return () => clearTimeout(timer);
    }
    if (play && !inViewPort && isLgMin) {
      setDockingMode(true);
    }

    if (play && !inViewPort && !isLgMin) {
      setDockingMode(false);
      setPlay(false);
    }

    if (play && inViewPort) {
      setDockingMode(false);
    }
    return () => {};
  }, [disableAutoplay, disableDockingMode, inViewPort, isLgMin, play]);

  if (consent === null) {
    return (
      <Wrapper>
        <NoConsent />
      </Wrapper>
    );
  }

  return (
    <Wrapper
      ref={videoContainerRef}
      className={className}
    >
      <Container
        $dockingMode={dockingMode}
        className={className}
        data-cy={dataCy}
      >
        <CloseButton
          $dockingMode={dockingMode}
          type='button'
          onClick={() => {
            setDockingMode(false);
            setDisableDockingMode(true);
            setPlay(false);
          }}
        >
          <CloseIconContainer>
            <CloseIconWhite />
          </CloseIconContainer>
        </CloseButton>
        {play || disableAutoplay ? (
          <IFrame
            $dockingMode={dockingMode}
            title='vod-video'
            src={videoUrl}
            scrolling='no'
            frameBorder='0'
            allowFullScreen
            allow='autoplay'
            className={className}
          />
        ) : (
          <NextCustomImage
            src='https://content.fortune.com/wp-content/uploads/2020/09/placeholder_16x9.jpg?resize=800,450'
            alt='Video Poster'
            width={800}
            height={450}
            priority
          />
        )}
      </Container>
    </Wrapper>
  );
};

export default VodVideo;
