import styled, { css } from 'styled-components';
import { font } from 'styles/globals';
import { Theme, getColor } from 'styles/theme';
import { arrowRight } from 'styles/icons';

const arrowSize = 20;
const itemsGap = 16;

const level0 = css`
  font-weight: 600;
  font-size: 24px;
`;

const level1 = css`
  font-weight: 500;
  font-size: 20px;
`;

const level2 = css`
  font-weight: 400;
  font-size: 16px;
`;

export const Item = styled.li<{ $nestingLevel: number; $subMenu: boolean; theme: Theme }>`
  display: inline-flex;
  align-items: center;
  position: relative;
  margin-left: ${(props) => 16 * (props.$nestingLevel - 1)}px;
  font-family: ${font.graphikCond};
  color: ${getColor('textPrimary')};
  justify-content: space-between;
  line-height: 120%;
  ${(props) => {
    switch (props.$nestingLevel) {
      case 0:
        return level0;
      case 1:
        return level1;
      default:
        return level2;
    }
  }}
  
  a {
    flex: 1;
    color:  ${getColor('textPrimary')};
    //extending the area of the anchor link to cover the gap between menu items
    margin: -${itemsGap / 2}px 0;
    padding: ${itemsGap / 2}px 0;
    padding-left: 16px;
  }

  > a:not(:only-child) {
    margin-right: 16px;
  }

  > a:only-child {
    margin-right: ${arrowSize}px;
  }
`;

export const Submenu = styled.ul<{ $visibility: string }>`
  display: ${(props) => (props.$visibility === 'true' ? 'flex' : 'none')};
  flex-direction: column;
  gap: ${itemsGap}px;
`;

export const Icon = styled.button<{ theme: Theme }>`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s ease-in-out;
  cursor: pointer;
  padding: 8px;
  margin: -8px;

  .arrow {
    ${arrowRight}
    border-color: ${getColor('iconPrimary')};
    padding: 5px;
    border-width: 0 2px 2px 0;
    transition: 0.15s ease-in-out;
  }

  &:hover  .arrow {
    border-color: ${getColor('iconAccentPrimary')};
  }

  &:focus  .arrow {
    border-color: ${getColor('iconSecondary')};
  }

  &.up {
    transform: rotate(-90deg);
  }
`;
