/* eslint-disable react/no-array-index-key */
import Slick from 'react-slick';
import NextArrow from 'components/Arrows/NextArrow';
import PrevArrow from 'components/Arrows/PrevArrow';
import { useEffect, useState } from 'react';
import * as S from './ExploreProductsStyles';

export interface Pill {
  text: string;
  href: string;
}

const sliderSettings = {
  accessibility: true,
  dots: false,
  infinite: false,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  rows: 1,
  slidesToScroll: 1,
  speed: 300,
  variableWidth: true,
};

const debounceFunction = (cb: () => void, delay: number) => {
  let timer: ReturnType<typeof setTimeout> | undefined;
  return () => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => cb(), delay);
  };
};

const ExploreProducts = ({ title, pillItems }: { title: string; pillItems: Pill[] }) => {
  const [slidesToShow, setSlidesToShow] = useState<number | undefined>(undefined);

  const calculateSlidesToShow = () => {
    const element = document.querySelector('.explore-our-products-widget');
    if (element instanceof HTMLElement) {
      const widgetWidth = element.offsetWidth;
      const calculatedWidth = Math.floor((widgetWidth - 48) / 170);
      setSlidesToShow(calculatedWidth);
    }
  };

  useEffect(() => {
    calculateSlidesToShow();

    const debounce = debounceFunction(calculateSlidesToShow, 300);

    window.addEventListener('resize', debounce);

    return () => {
      window.removeEventListener('resize', debounce);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.Wrapper className='explore-our-products-widget'>
      <S.Title>{title}</S.Title>
      <Slick
        {...sliderSettings}
        slidesToShow={slidesToShow}
        className='explore-our-products-slider'
      >
        {pillItems.map((pill) => (
          <S.Pill
            href={pill.href}
            key={pill.href}
          >
            {pill.text}
          </S.Pill>
        ))}
      </Slick>
    </S.Wrapper>
  );
};

export default ExploreProducts;
