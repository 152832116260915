import { MenuItemProps } from 'interfaces/navigation/Header';
import { useState } from 'react';
import cx from 'classnames';
import SubCategoryItem from 'components/TopicBasedNavigation/SubmenuV2/SubCategoryItem';
import * as S from './SubmenuStyles';

export interface SubMenuV2Props {
  dataCyLabel: string;
  subMenu: MenuItemProps[] | null;
  parentLabel: string;
  isSimple: boolean;
}

const SubMenuV2 = ({ subMenu, dataCyLabel, parentLabel, isSimple }: SubMenuV2Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeSubCategoryID, setActiveSubCategoryID] = useState<string | undefined>(subMenu?.[0].id);

  if (!subMenu) return null;

  const handleOnOpenSubMenu = () => {
    setIsOpen(true);
  };

  const handleOnCloseSubMenu = () => {
    setIsOpen(false);
    setActiveSubCategoryID(subMenu?.[0].id);
  };

  return (
    <S.Wrapper
      data-cy={dataCyLabel}
      onMouseEnter={handleOnOpenSubMenu}
      onMouseLeave={handleOnCloseSubMenu}
      $subCategoriesNr={subMenu.length}
      className={cx({ isOpen })}
      $isSimple={isSimple}
    >
      {subMenu.map((subCategory) => (
        <SubCategoryItem
          key={subCategory.id}
          item={subCategory}
          parentLabels={[parentLabel]}
          activeSubcategoryID={activeSubCategoryID}
          setActiveSubCategoryID={setActiveSubCategoryID}
        />
      ))}
    </S.Wrapper>
  );
};

export default SubMenuV2;
