import { useEffect } from 'react';
import { useAdvertisingConsent } from 'hooks/useAdvertisingConsent';
import { NoConsent, Wrapper } from '../VodVideo/VodVideoStyles';

export interface StnVideoProps {
  videoId: string;
  adTags?: {
    adtagname: string;
    description: string;
    slug: string;
  }[];
}

const StnVideo = ({ videoId, adTags }: StnVideoProps) => {
  const consent = useAdvertisingConsent();
  const updateStnCid = adTags?.find((tag) => tag.adtagname === 'No Video Ads');
  const updatedVideoId = updateStnCid ? videoId.replace(/-14497$/g, '-16481') : videoId;

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://embed.sendtonews.com/player3/embedcode.js?SC=${updatedVideoId}`;
    script.async = true;
    script.type = 'text/javascript';
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [updatedVideoId]);

  if (consent === null) {
    return (
      <Wrapper>
        <NoConsent />
      </Wrapper>
    );
  }

  return (
    <div
      className={`stn-video-player s2nPlayer k-${updatedVideoId}`}
      data-type='float'
    />
  );
};

export default StnVideo;
