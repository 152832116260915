import React, { useCallback, useEffect } from 'react';
import { TrackableInhouseAd } from 'interfaces/components/InhouseAd';
import InhouseAdContainter from 'components/Ad/InhouseAd/InhouseAdStyles';
import Image from 'components/Image';
import useBreakpoint from 'hooks/useBreakpoint';
import { pushToDataLayer } from 'services/Gtm/functions';
import { toKebabCase } from 'utils/stringUtils';
import { InhouseAdTrackingObject } from 'services/Gtm/entities';

const AD_CONTAINER_CLASSNAME = 'fortune-inhouse-ad';
const TRACKING_PROPS_PREFIX = 'data-tracking';

interface HtmlTrackingPropsMap {
  [key: string]: string | null;
}

interface InhouseAdProps {
  inhouseAd: TrackableInhouseAd;
  pageType?: string;
}

const InhouseAd: React.FC<InhouseAdProps> = ({ inhouseAd, pageType }) => {
  const isLgMin = useBreakpoint('lgMin');
  const isXlMin = useBreakpoint('xlMin');

  const adSlotType = pageType === 'article-v2' ? 'wide' : 'normal';
  const currentAdFormat = inhouseAd.adFormats[(adSlotType === 'wide' ? isLgMin : isXlMin) ? 'desktop' : 'mobile'];

  const getTrackingEvent = useCallback(
    (event: string): InhouseAdTrackingObject => ({
      adId: inhouseAd.adId,
      event,
      placementId: inhouseAd.placementId,
      ...inhouseAd.tracking,
    }),
    [inhouseAd],
  );

  // Generate the same tracking props as in the function above, but for HTML
  // The props get prefixd wth TRACKING_PROPS_PREFIX and are hyphenated
  const getGtmTrackingHtmlProps = useCallback(() => {
    const trackingProps: HtmlTrackingPropsMap = {
      adId: inhouseAd.adId,
      placementId: inhouseAd.placementId,
      ...inhouseAd.tracking,
    };

    const htmlTrackingProps: HtmlTrackingPropsMap = {};

    Object.keys(trackingProps).forEach((currentProp: string) => {
      htmlTrackingProps[`${TRACKING_PROPS_PREFIX}-${toKebabCase(currentProp)}`] = trackingProps[currentProp];
    });

    return htmlTrackingProps;
  }, [inhouseAd]);

  useEffect(() => {
    // Ad load event
    // This does not count as an impression
    // In fact, this helps us find out how many ad displays
    // in page have actually generated impressions
    pushToDataLayer(getTrackingEvent('fortune.inhouseAd.request'));
  }, [getTrackingEvent]);

  return (
    <InhouseAdContainter
      className={AD_CONTAINER_CLASSNAME}
      // Spread HTML tracking props for GTM
      {...getGtmTrackingHtmlProps()}
      adSlotType={adSlotType}
      href={inhouseAd.cta}
    >
      <Image
        alt={inhouseAd.name}
        src={currentAdFormat.imageUrl}
        width={currentAdFormat.imageWidth}
        height={currentAdFormat.imageHeight}
      />
    </InhouseAdContainter>
  );
};

export default InhouseAd;
