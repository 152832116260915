import styled from 'styled-components';
import { breakpoint, font } from 'styles/globals';
import { Theme, getColor } from 'styles/theme';
import CustomLink from 'components/Globals/Base/Link';

export const Wrapper = styled.div<{ theme: Theme }>`
  background-color: ${getColor('shadeBrandPrimary50')};
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  position: relative;

  &:has(.slick-next:not(.slick-disabled))::after  {
    content: "";
    position: absolute;
    height: 100%;
    right: 0;
    top: 0;
    width: 50px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    pointer-events: none;
    z-index: 2;
  }
  
  &:has(.slick-prev:not(.slick-disabled))::before  {
    content: "";
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    width: 50px;
    background: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    pointer-events: none;
    z-index: 2;
  }

  .explore-our-products-slider {
    .slick-list {
      .slick-track{
        margin: 0;
        display: flex;
      }
      .slick-slide {
        padding: 10px;
      }
    }

    .slick-prev,
    .slick-next {
      z-index: 3;

      &.slick-disabled {
        display: none;
      }
    }
  }
`;

export const Title = styled.div<{ theme: Theme }>`
  margin-left: 12px;
  font-family: ${font.saolText};
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  z-index: 4;
  
  @media (${breakpoint.mmMin}) {
    font-size: 24px;
    line-height: 28px;
  }
`;

export const Pill = styled(CustomLink)<{ theme: Theme }>`
  max-height: 48px;
  background-color: ${getColor('surfaceBrand')};
  border-radius: 8px;
  line-height: 24px;
  font-size: 16px;
  font-weight: 600;
  font-family: ${font.graphikCond};
  padding: 12px 16px;
  color: ${getColor('textWhite')}!important;
  text-align: center;
  text-decoration: none!important;
  min-width: 150px;
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &:hover, &:focus {
    background-color: ${getColor('shadeBrandPrimary900')};
    color: ${getColor('textWhite')}; 
  }
  &:focus {
    box-shadow: 0px 0px 7px 0px ${getColor('textLink')};
  }  
  &:active > *:not(.no-active) {
    color: ${getColor('textWhite')}; 
  }
  &:active {
    background-color: ${getColor('surfaceBrand')};
    box-shadow: 0px 0px 7px 0px ${getColor('textLink')};
  }
`;
