import type { AppProps } from 'next/app';
import Fonts from 'styles/fonts';
import Layout from 'components/Layout';
import AdsProvider from 'providers/AdProvider';
import { UserProvider } from 'providers/UserProvider';
import { PageAdConfig } from 'interfaces/components/Ad';
import { PianoPageConfig } from 'services/Piano/entities/PianoPageConfig';
import { QuerylyScript } from 'services/Queryly';
import { HeaderNav } from 'interfaces/navigation/Header';
import Head from 'components/Head';
import { HeadData } from 'utils/metaDataUtils/headData';
import { PageViewDataLayerObject } from 'services/Gtm/entities';
import { useRouter } from 'next/router';
import Loader from 'components/Loader';
import 'styles/Global.css';
import { IterablePageData } from 'services/Iterable/entities';
import ErrorBoundary from 'components/ErrorBoundary';
import Error500 from 'components/Error500';
import { PermutivePageConfig } from 'services/Permutive/entities';
import { BypassPaywall } from 'interfaces/BypassPaywall';
import { FooterProps } from 'components/Footer';
import { RedVentureScript } from 'services/RedVenture';
import { BinanceWidgetScript } from 'services/Binance';
import { PushlyScript } from 'services/Pushly';
import { VwoScript } from 'services/Vwo';
import { Microsites } from 'constants/constants';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import { PersonalizationProvider } from 'providers/PersonalizationProvider';
import theme from 'styles/theme';
import GoogleTagManager from 'services/Gtm';
import NoticeProvider from 'providers/NoticeProvider';
import PaywallProvider from 'providers/PaywallProvider';
import 'styles/globalReset.css';

export interface PageProps {
  bypassPaywall?: BypassPaywall;
  dataLayer: PageViewDataLayerObject;
  headData: HeadData;
  headerNav: HeaderNav;
  footer: FooterProps;
  iterablePageViewData: IterablePageData;
  pageAdConfig: PageAdConfig;
  permutivePageConfig: PermutivePageConfig;
  pianoPageConfig: PianoPageConfig;
  redVenture?: boolean;
  widgetCss?: string | null;
}

const App = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();

  if (router.isFallback) {
    return <Loader />;
  }

  const {
    bypassPaywall,
    dataLayer,
    headData: {
      authors,
      databaseId,
      dateGmt,
      description,
      faqSchema,
      hasVanityUrl,
      image,
      imageTwitter,
      jsonLdSchema,
      keywords,
      link,
      rssFeedLink,
      socialDescription,
      socialTitle,
      title,
      pageType,
      premiumCategory,
      primarySection,
      siteName,
      canonicalUrl,
      tagNames,
      widgetCss,
      prefixedDatabaseId,
    },
    footer,
    iterablePageViewData,
    headerNav,
    pageAdConfig,
    permutivePageConfig,
    pianoPageConfig,
    redVenture,
    ...rest
  } = pageProps as PageProps;
  const leaderboard = pageAdConfig ?
    pageAdConfig.slots.filter((slot) => slot.placementName === 'Leaderboard').length > 0 :
    false;

  const useBinanceWidget = Microsites.Crypto === headerNav?.subDomain;
  const usePushly = process.env.FEATURE_FLAG_PUSHLY;

  return (
    <UserProvider {...{ bypassPaywall, iterablePageViewData, pianoPageConfig }}>
      <Head
        {...{
          authors,
          canonicalUrl,
          databaseId,
          dateGmt,
          description,
          faqSchema,
          hasVanityUrl,
          image,
          imageTwitter,
          jsonLdSchema,
          keywords,
          link,
          pageAdConfig,
          pageType,
          pianoPageConfig,
          prefixedDatabaseId,
          premiumCategory,
          primarySection,
          redVenture,
          rssFeedLink,
          siteName,
          socialDescription,
          socialTitle,
          subDomain: headerNav?.subDomain,
          tagNames,
          title,
          useBinanceWidget,
          widgetCss,
        }}
      />
      <ThemeProvider theme={theme(headerNav?.subDomain)}>
        <ErrorBoundary fallback={<Error500 />}>
          <PersonalizationProvider>
            <GoogleTagManager {...{ dataLayer }}>
              <PaywallProvider {...{ pianoPageConfig }}>
                <AdsProvider {...{ pageAdConfig, permutivePageConfig }}>
                  <StyleSheetManager enableVendorPrefixes>
                    <NoticeProvider>
                      <Layout {...{ footer, hasVanityUrl, headerNav, leaderboard }}>
                        <ErrorBoundary fallback={<Error500 />}>
                          <Component {...rest} />
                        </ErrorBoundary>
                      </Layout>
                    </NoticeProvider>
                  </StyleSheetManager>
                </AdsProvider>
              </PaywallProvider>
              <Fonts />
              <QuerylyScript />
              {redVenture && <RedVentureScript />}
              {useBinanceWidget && <BinanceWidgetScript />}
              {usePushly && <PushlyScript />}
              <VwoScript />
            </GoogleTagManager>
          </PersonalizationProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </UserProvider>
  );
};

export default App;
