/* eslint-disable import/prefer-default-export */
import Script from 'next/script';

const BinanceWidgetScript: React.FC = () => (
  <Script
    src='https://public.bnbstatic.com/unpkg/growth-widget/cryptoCurrencyWidget@0.0.8.min.js'
    async
  />
);

export { BinanceWidgetScript };
