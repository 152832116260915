import { KeyboardEvent, useRef, useState } from 'react';
import useClickOutside from 'hooks/useClickOutside';
import * as S from './DropdownStyles';

export interface Option {
  value: string;
  label: string;
}

export interface Props {
  id?: string;
  isExpanded?: boolean;
  selected?: string | number;
  onBtnClick?: () => void;
  onOptionClick: (value: string, index: number) => void;
  className?: string;
  label?: string;
  assistiveText?: string;
  placeholder?: string;
  list: Option[] | string[];
  required?: boolean;
  disabled?: boolean;
  size?: 'large' | 'small';
  dataCy?: string;
  hasDisclaimer?: boolean;
  // TODO: FFD-6414 remove this temporary flag
  shouldUseValue?: boolean;
}

const Dropdown = ({
  id = '',
  label = '',
  assistiveText = '',
  selected,
  placeholder,
  isExpanded = false,
  onBtnClick,
  onOptionClick,
  className = '',
  required = false,
  size = 'large',
  disabled = false,
  list,
  dataCy = '',
  hasDisclaimer = false,
  shouldUseValue,
}: Props) => {
  const [dropdownOpened, setDropdownOpened] = useState(isExpanded);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const liRef = useRef<{ [key: number]: HTMLLIElement | null }>({});

  const dropdownRef = useRef<HTMLDivElement>(null);
  useClickOutside(dropdownRef, () => setDropdownOpened(false));

  const handleClick = () => {
    if (onBtnClick) onBtnClick();
    setDropdownOpened(!dropdownOpened);
  };

  const setIndex = (index: number) => {
    const tabLi = liRef?.current[index];
    if (tabLi) {
      tabLi.focus();
      setSelectedIndex(index);
    }
  };

  const onKeyDown = (event: KeyboardEvent, val: string, index: number) => {
    const nextItem = () => setIndex(selectedIndex + 1);
    const prevItem = () => setIndex(selectedIndex - 1);
    const selectItem = () => {
      onOptionClick(val, index);
      setDropdownOpened(false);
    };

    const keyMap = {
      ArrowDown: nextItem,
      ArrowUp: prevItem,
      Enter: selectItem,
    } as { [key: string]: () => void };

    const action = keyMap[event.key];

    if (action) {
      event.preventDefault();
      action();
    }
  };

  const selectedOptionText =
    ((list as Option[])?.find((option) => option.value === selected) as Option)?.label || selected;

  return (
    <S.Wrapper
      className={`${className} `}
      ref={dropdownRef}
      data-cy={dataCy}
    >
      {label && (
        <S.Label
          id='listbox_level'
          htmlFor={id}
          className={`${className}-label ${!label ? 'hidden' : ''}`}
          data-cy={`${dataCy}-label`}
        >
          {label} {required && <span>*</span>}
          {hasDisclaimer && <span className='disclaimer'>*</span>}
        </S.Label>
      )}
      <S.Button
        $size={size}
        type='button'
        role='combobox'
        id={id}
        aria-label={`Selected list item ${selectedOptionText}`}
        aria-controls='listbox'
        aria-haspopup='listbox'
        aria-expanded={dropdownOpened}
        onClick={handleClick}
        disabled={disabled}
        title={`${selectedOptionText || placeholder}`}
        className={`${className}-button ${placeholder && !selected ? 'has-placeholder' : ''}`}
        data-cy={`${dataCy}-button`}
      >
        <span>{selectedOptionText || placeholder}</span>
      </S.Button>
      <S.List
        role='listbox'
        aria-labelledby='listbox_level'
        id='listbox'
        tabIndex={dropdownOpened ? 0 : -1}
        className={dropdownOpened && !disabled ? 'active' : ''}
        data-cy={`${dataCy}-list`}
      >
        {list.length &&
          list.map((item, index) => {
            const isObject = typeof item !== 'string';

            const val = isObject ? item?.value : item;
            const text = isObject ? item?.label : item;
            const showItem = val && text && text !== selected;

            return showItem ? (
              <S.ListItem
                role='option'
                aria-selected={selected === ((list[index] as Option)?.value || list[index])}
                key={val}
                tabIndex={dropdownOpened && selectedIndex === index ? 0 : -1}
                onFocus={() => setSelectedIndex(index)}
                onKeyDown={(event) => onKeyDown(event, val, index)}
                onClick={() => {
                  onOptionClick(shouldUseValue ? val : text, index);
                  setDropdownOpened(false);
                }}
                ref={(element) => {
                  liRef.current[index] = element;
                }}
              >
                {text}
              </S.ListItem>
            ) : null;
          })}
      </S.List>
      {assistiveText && (
        <S.Text
          className={`assistive-text ${className}`}
          data-cy={`${dataCy}-assistive-text`}
        >
          {assistiveText}
        </S.Text>
      )}
    </S.Wrapper>
  );
};

export default Dropdown;
