const ADS_TEST_IDS = {
  IN_STREAM: 'in-stream',
  LEADERBOARD: 'leaderboard',
};

export type TestIds = {
  [key in keyof typeof ADS_TEST_IDS]?: string;
};

const getAdsTestId = (value: keyof TestIds) => ADS_TEST_IDS[value];

export default getAdsTestId;
