const setUrlParam = (urlData: URL, key: string, value: string, overwrite?: boolean) => {
  if (!urlData.searchParams.get(key) || overwrite) {
    urlData.searchParams.set(key, value);
  }
};

// Parse CTA Url and set standard UTM Parameters if not present already with same rules as FE-860
const setUtmParams = (ctaUrl: string, entityId?: string, utmCampaign?: string, utmContent = 'instream') => {
  let url = ctaUrl;
  // Replace predefined constants
  url = url.replaceAll('[dynamic]post-id[/dynamic]', entityId || 'null');
  url = url.replaceAll('PAGE_ID', entityId || 'null');

  url = url.replaceAll('CAMPAIGN_ID', utmCampaign || 'null');
  url = url.replaceAll('POSITION_ID', utmContent);

  const urlData = new URL(url);

  setUrlParam(urlData, 'lsrc', 'fortunecplsite');
  setUrlParam(urlData, 'utm_source', 'fortunecplsite');

  setUrlParam(urlData, 'utm_campaign', utmCampaign ? `${utmCampaign}` : 'null');
  setUrlParam(urlData, 'l', utmCampaign ? `${utmCampaign}` : 'null');

  setUrlParam(urlData, 'c', utmContent);
  setUrlParam(urlData, 'utm_content', utmContent);

  setUrlParam(urlData, 'utm_medium', 'strategic_partnership');

  setUrlParam(urlData, 'ef_id', entityId ? `${entityId}` : 'null');

  return urlData.href;
};

export default setUtmParams;
