import { trackEvent } from 'services/Gtm/functions';
import { EventData } from 'services/Gtm/entities';
import * as S from './PrevArrowStyles';

export interface PrevArrowProps {
  className?: string;
  onClick?: () => void;
  trackingData?: EventData;
  dataCy?: string;
  ariaLabel?: string;
}

const PrevArrow = ({ className = '', onClick, trackingData, dataCy = '', ariaLabel }: PrevArrowProps) => (
  <S.PrevArrowWrapper
    className={`${className} prev`}
    onClick={() => {
      if (onClick) onClick();
      if (trackEvent && trackingData) trackEvent({ ...trackingData, eventLabel: 'previous arrow click' });
    }}
    data-cy={dataCy}
    aria-label={ariaLabel ?? 'Previous'}
    aria-labelledby='Previous Button'
    role='presentation'
    title='Previous Button'
  >
    <S.PrevArrow />
  </S.PrevArrowWrapper>
);

export default PrevArrow;
