import React from 'react';
import getAdsTestId from 'constants/testsIds/ads';
import AdSlot from './AdSlot';
import { LeaderboardAd } from './Styles';

interface LeaderboardProps {
  id: number;
}

const Leaderboard: React.FC<LeaderboardProps> = ({ id }: LeaderboardProps) => (
  <AdSlot
    component={LeaderboardAd}
    placementName='Leaderboard'
    index={id + 1}
    slotId={`Leaderboard${id}`}
    dataCy={getAdsTestId('LEADERBOARD')}
  />
);

export default Leaderboard;
