import useBreakpoint from 'hooks/useBreakpoint';
import Link from 'components/Globals/Base/Link';
import { trackEvent } from 'services/Gtm/functions';
import { UserContext } from 'providers/UserProvider';
import { ForwardedRef, forwardRef, useContext } from 'react';
import { Microsites } from 'constants/constants';
import { DrawerToggleButton } from 'components/Header/HeaderStyles';
import * as S from './DrawerMenuStyles';

interface DrawerMenuProps {
  children: JSX.Element | JSX.Element[];
  subDomain?: string;
  handleClick: () => void;
  keyboardToggle: () => void;
}

const DrawerMenu = (
  { children, handleClick, keyboardToggle, subDomain }: DrawerMenuProps,
  ref: ForwardedRef<HTMLDivElement> | null,
) => {
  const isTablet = useBreakpoint('mdMin');
  const { userState: user } = useContext(UserContext);

  const isSubscribeBtnDisplayed =
    Microsites.Well !== subDomain &&
    Microsites.Recommends !== subDomain &&
    Microsites.Education !== subDomain &&
    !user.hasAccess;

  const subscribeBtnTrackingData = {
    contentPlacementCD: 'header',
    eventAction: 'top header links',
    eventCategory: 'navigation',
    eventLabel: 'subscribe now',
  };

  const closeIconTrackingData = {
    contentPlacementCD: 'Global Side navigation',
    eventAction: 'Global Side navigation toggle',
    eventCategory: 'navigation',
    eventLabel: 'close',
  };

  const homepageLinkTrackingData = {
    contentPlacementCD: 'Global Side navigation',
    eventAction: 'Global Side navigation - level 1',
    eventCategory: 'navigation',
    eventLabel: 'Home Page',
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLLabelElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      handleClick();
      keyboardToggle();
    }
  };

  const homepageLink =
    subDomain && [Microsites.Recommends, Microsites.Education].includes(subDomain) ? '/recommends/' : '/';

  return (
    <>
      <S.ContainerDrawer
        className='drawer-menu'
        ref={ref}
      >
        <S.CloseWrapper>
          <S.TopLeftWrapper $isSubscribeBtn={isSubscribeBtnDisplayed}>
            <DrawerToggleButton
              htmlFor='drawer-toggle'
              className='close-menu'
              onClick={() => {
                if (trackEvent && closeIconTrackingData) trackEvent(closeIconTrackingData);
              }}
              onKeyDown={handleKeyDown}
              aria-label='Close menu'
              data-cy='closeButton'
              tabIndex={0}
            />
            <Link
              className='link'
              href={homepageLink}
              dataCy='homePageLink'
              trackingData={homepageLinkTrackingData}
              type='secondary'
            >
              Home Page
            </Link>
          </S.TopLeftWrapper>
          {isTablet && isSubscribeBtnDisplayed && (
            <S.MyAccountWrapper>
              <Link
                className='subscribe-link'
                href='/subscribe/'
                trackingData={subscribeBtnTrackingData}
                dataCy='subscribeBtnDrawer'
                type='accentButton'
                hasRightArrow={false}
              >
                Subscribe Now
              </Link>
            </S.MyAccountWrapper>
          )}
        </S.CloseWrapper>
        {children}
      </S.ContainerDrawer>
      <S.OutsideTheMenu
        className='overlay'
        onClick={handleClick}
      />
    </>
  );
};

export default forwardRef(DrawerMenu);
