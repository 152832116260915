/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable import/prefer-default-export */
import { RedirectClickData, UseAnalyticsData } from 'interfaces/Analytics';
import { useEffect, useMemo, useState, useContext } from 'react';
import { getParser } from 'bowser';
import { ArticleContext } from 'providers/ArticleProvider';
import { v4 as uuidv4 } from 'uuid';

const generateUuid = () => uuidv4();

const getImpressionUrlEncoded = () => encodeURIComponent(window.location.href);

const getUserAgent = () => navigator.userAgent;

const getDeviceType = () => {
  const parser = getParser(navigator.userAgent);
  return parser.getPlatformType();
};

const getSourceId = (href: string) => {
  try {
    const url = new URL(href);
    const params = new URLSearchParams(url.search);
    return params.get('src') || params.get('source') || 'none found';
  } catch {
    return 'none found';
  }
};

const generateISODate = () => new Date().toISOString();

export const useAnalyticsData = (href?: string): UseAnalyticsData => {
  const [analyticsData, setAnalyticsData] = useState<RedirectClickData | null>(null);
  const { postId } = useContext(ArticleContext) || {};

  const pvUUID = useMemo(() => generateUuid(), []);
  const sourceId = useMemo(() => (href ? getSourceId(href) : ''), [href]);

  useEffect(() => {
    const userAgent = getUserAgent();
    const impressionUrl = getImpressionUrlEncoded();
    const pvISODate = generateISODate();
    const deviceType = getDeviceType();

    const data = {
      clickISODate: '',
      clickUUID: '',
      deviceType,
      impressionUrl,
      postId,
      pvISODate,
      pvUUID,
      redirectUrl: href ? encodeURIComponent(href) : '',
      sourceId,
      userAgent,
    };

    setAnalyticsData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    data: analyticsData,
  };
};
