/* eslint-disable no-param-reassign */
import React, { useContext } from 'react';
import { useAdvertisingConsent } from 'hooks/useAdvertisingConsent';
import { PageAdConfig } from 'interfaces/components/Ad';
import { PermutivePageConfig } from 'services/Permutive/entities';
import Permutive from 'services/Permutive';
import { UserContext } from 'providers/UserProvider';
import Bombora from 'services/Bombora';
import AdManager from './AdManager';

interface AdProviderProps {
  children: JSX.Element;
  pageAdConfig?: PageAdConfig;
  permutivePageConfig: PermutivePageConfig;
}

/**
 * If no consent is give, do not load ads.
 * Load Permutive before the ad library.
 */
const AdProvider: React.FC<AdProviderProps> = ({ children, pageAdConfig, permutivePageConfig }) => {
  const adConsent = useAdvertisingConsent();
  const { hasLoggedIn } = useContext(UserContext);

  if (!pageAdConfig || !adConsent) {
    return children;
  }

  if (hasLoggedIn) {
    pageAdConfig.loginStatus = 'logged-in';
  } else {
    pageAdConfig.loginStatus = 'guest';
  }

  return (
    <>
      <Bombora />
      <AdManager pageAdConfig={pageAdConfig}>{children}</AdManager>
      <Permutive
        permutivePageConfig={permutivePageConfig}
        pageAdConfig={pageAdConfig}
      />
    </>
  );
};

export default AdProvider;
