import { css } from 'styled-components';
import { getColor, Theme } from 'styles/theme';
import { font } from 'styles/globals';

export const paragraphs = css`
  p {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  sup {
    vertical-align: top;
    position: relative;
    top: -0.15em;
  }
`;

export const codeBlocks = css`
  pre, .wp-block-code {
    white-space: pre-wrap;
    word-wrap: break-word;
  }
`;

export const ctaCaption = css<{ theme: Theme }>`
  color: ${getColor('textSecondary')} !important;
  font-family: ${font.graphikCond};
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.5px;
  text-align: center;
  margin: 8px 0 0 0;  
`;
