import { EventData } from 'services/Gtm/entities';
import { trackEvent } from 'services/Gtm/functions';
import * as S from './NextArrowStyles';

export interface NextArrowProps {
  className?: string;
  onClick?: () => void;
  trackingData?: EventData;
  dataCy?: string;
  ariaLabel?: string;
}

const NextArrow = ({ className = '', onClick, trackingData, dataCy = '', ariaLabel }: NextArrowProps) => (
  <S.NextArrowWrapper
    className={`${className} next`}
    onClick={() => {
      if (onClick) onClick();
      if (trackEvent && trackingData) trackEvent({ ...trackingData, eventLabel: 'next arrow click' });
    }}
    data-cy={dataCy}
    aria-label={ariaLabel ?? 'Next'}
    aria-labelledby='Next Button'
    role='presentation'
    title='Next Button'
  >
    <S.NextArrow />
  </S.NextArrowWrapper>
);

export default NextArrow;
