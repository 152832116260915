import { InhouseAdProps } from 'interfaces/components/InhouseAd';
import cookies from 'js-cookie';
import { PERSONALIZATION_API_HOST, PERSONALIZATION_API_KEY } from './constants';

export type PersonalizedAds = { [pageType: string]: { [placement: string]: { [index: string]: InhouseAdProps } } };
interface QueryParams {
  id: string;
  type: string;
  pageType?: string;
  customAd?: any;
}

const getPersonalizedAds = async (pageType?: string, customAd?: string | null) => {
  const anonId = cookies.get('ajs_anonymous_id');
  const userId = cookies.get('ajs_user_id');
  try {
    const queryParams: QueryParams = {
      id: `${userId || anonId}`,
      type: userId ? 'user_id' : 'anonymous_id',
      ...(pageType && { pageType }),
      // uc - only for testing purposes
      ...(customAd && { uc: customAd }),
    };

    const queryParamsObject: Record<string, string> = {
      ...queryParams,
    };

    const response = await fetch(
      `${PERSONALIZATION_API_HOST}/getCustomContent?${new URLSearchParams(queryParamsObject).toString()}`,
      {
        headers: {
          'x-api-key': PERSONALIZATION_API_KEY,
        },
        method: 'GET',
      },
    );

    if (!response.ok) throw new Error('Errored!');

    return (await response.json()) as PersonalizedAds;
  } catch (error) {
    // getPersonalizedAds failed
    return {};
  }
};
export default getPersonalizedAds;
