export const bodyFonts = {
  georgia: 'georgia',
  graphikCompact: 'graphikCompact',
  graphikCond: 'graphikCond',
};

export const bodyWeight = {
  bold: '700',
  light: '300',
  regular: '400',
  semiBold: '600',
};

export const bodySizes = {
  default: '1rem',
  large: '1.25rem',
  medium: '1.125rem',
  small: '0.875rem',
  xlarge: '1.5rem',
  xsmall: '0.75rem',
  xxlarge: '2em',
};

export const saolSizes = {
  large: '3rem',
  level1: '2.5rem',
  level2: '2rem',
  level3: '1.5rem',
  level4: '1.25rem',
  level5: '1rem',
  level6: '0.875rem',
};

export const graphikCondSizes = {
  xlarge: '3.75rem',
  xxlarge: '6rem',
  ...saolSizes,
};
