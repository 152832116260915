/* eslint-disable @next/next/next-script-for-ga */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-invalid-html-attribute */
import NextHead from 'next/head';
import { useRouter } from 'next/router';
import { useContext, useEffect, useRef, useState } from 'react';
import { PianoPageConfig } from 'services/Piano/entities/PianoPageConfig';
import { addPaywallSchema } from 'utils/paywallUtils';
import { formatAmpersand } from 'utils/miscUtils/myFavorites';
import { PageType } from 'interfaces/content/articles/Post';
import { PageAdConfig } from 'interfaces/components/Ad';
import { JsonLdSchema, addStnMetaSchema } from 'utils/metaDataUtils/setJsonLdSchema';
import CookieService from 'utils/miscUtils/CookieService';
import { useAdvertisingConsent } from 'hooks/useAdvertisingConsent';
import { UserContext } from 'providers/UserProvider';
import { commonColors } from 'styles/colors';
import { objectToLiteralString } from 'utils/stringUtils';

export interface HeadProps {
  authors?: string;
  databaseId: string;
  dateGmt: string | null;
  description: string;
  faqSchema: string;
  hasVanityUrl?: boolean;
  image: string;
  imageTwitter?: string;
  jsonLdSchema: string;
  keywords: string;
  link: string;
  pageAdConfig?: PageAdConfig;
  pianoPageConfig: PianoPageConfig;
  premiumCategory: string;
  primarySection: string;
  socialDescription: string;
  socialTitle: string;
  tagNames: string[];
  title: string;
  pageType: PageType;
  canonicalUrl: string | null;
  redVenture?: boolean;
  siteName?: string;
  subDomain?: string;
  useBinanceWidget?: boolean;
  rssFeedLink: string | null;
  widgetCss?: string | null;
  prefixedDatabaseId?: string | null;
}
interface OneTrustUser {
  dataSubjectParams: {
    id: string;
    isAnonymous: boolean;
    token: string;
  };
}

const Head = (props: HeadProps) => {
  const router = useRouter();
  const referrer = useRef<string>('');
  const pageTitle = useRef<string>('');
  const queryParamsRef = useRef<string>('');
  const { userState: user } = useContext(UserContext);
  const [isMounted, setIsMounted] = useState(false);
  const oneTrustConsent = useAdvertisingConsent();
  const [oneTrustReload, setOneTrustReload] = useState(false);
  const [oneTrustUserObj, setOneTrustUserObj] = useState<OneTrustUser | null | undefined>(null);

  const {
    authors,
    pageType,
    databaseId,
    dateGmt,
    description,
    prefixedDatabaseId,
    faqSchema,
    hasVanityUrl,
    image,
    imageTwitter,
    jsonLdSchema,
    keywords,
    widgetCss,
    link,
    pageAdConfig,
    pianoPageConfig,
    premiumCategory,
    primarySection,
    socialDescription,
    socialTitle,
    title,
    tagNames,
    canonicalUrl,
    redVenture,
    siteName = 'Fortune',
    subDomain,
    useBinanceWidget,
    rssFeedLink = '',
  } = props;

  let initialCanonical;

  if (canonicalUrl && canonicalUrl !== '') {
    initialCanonical = canonicalUrl;
  } else {
    initialCanonical = link;
  }
  const [canonical, setCanonical] = useState(initialCanonical);

  useEffect(() => {
    if (hasVanityUrl) {
      setCanonical(window.location.href);
    }
  }, [hasVanityUrl]);

  useEffect(() => {
    setIsMounted(true);
    queryParamsRef.current = window.location.search;
    referrer.current = document.referrer;
    pageTitle.current = document.title;
  }, []);

  useEffect(() => {
    const fetchToken = async () => {
      if (user.isLoggedIn && user.pianoUid) {
        try {
          const response = await fetch(`/api/getJwtToken/${user.pianoUid}/`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const userToken = (await response.json()) as string;
          setOneTrustUserObj({
            dataSubjectParams: {
              id: user.pianoUid,
              isAnonymous: false,
              token: userToken,
            },
          });
          setOneTrustReload(false);
        } catch (error) {
          setOneTrustUserObj(undefined);
        }
      } else {
        setOneTrustUserObj(undefined);
        setOneTrustReload(false);
      }
    };

    if (user.isLoggedIn !== null) {
      fetchToken().catch((error) => {
        console.error('Error during fetchToken execution:', error);
      });
    }
  }, [user.isLoggedIn, user.pianoUid]);

  useEffect(() => {
    if (!oneTrustReload && user.isLoggedIn !== null) {
      setOneTrustReload(true);
    }
  }, [oneTrustUserObj]);

  let jsonLdSchemaFinal;
  if (
    pianoPageConfig &&
    [
      'article',
      'company',
      'longform',
      'franchise-list',
      'franchise-list-item',
      'franchise-list-item-company',
      'franchise-list-search',
    ].includes(pianoPageConfig.pageType)
  ) {
    jsonLdSchemaFinal = addPaywallSchema(jsonLdSchema, pianoPageConfig);
  } else {
    jsonLdSchemaFinal = jsonLdSchema;
  }

  if (pageAdConfig && jsonLdSchemaFinal && (JSON.parse(jsonLdSchemaFinal) as JsonLdSchema).stnMeta) {
    jsonLdSchemaFinal = addStnMetaSchema(jsonLdSchemaFinal, pageAdConfig);
  }

  function getRobotsContent() {
    const robotsMetaContent: String[] = [];

    if (['evergreen', 'article'].includes(pageType)) {
      robotsMetaContent.push('max-image-preview:large');
    }

    return robotsMetaContent.toString().replaceAll(',', ', ');
  }

  function setUserObjScriptVal(obj: OneTrustUser | null | undefined) {
    if (obj) {
      return objectToLiteralString(obj);
    }
    return "''";
  }

  const fuidCookie = CookieService.get('fuid') || '';
  const pianoUserIdCookie = CookieService.get('pianoUid') || '';
  const pianoVisitorIdCookie = CookieService.get('_pcid') || '{}';
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  const parsedPianoVisitorId = JSON.parse(pianoVisitorIdCookie).browserId;
  const gtmReferrerCookie = CookieService.get('__gtm_referrer') || '';
  const campaignUrlCookie = CookieService.get('__gtm_campaign_url') || '';
  const gacidCookie = CookieService.get('_ga')?.slice(6) || '';

  const segmentWriteKey = process.env.SEGMENT_WRITE_KEY;
  // const gtmAuth = process.env.GTM_AUTH;
  // const gtmPreview = process.env.GTM_PREVIEW;
  const dataDomainScriptId = process.env.ONE_TRUST_DOMAIN_ID;
  const oneTrustScript = `https://cdn.cookielaw.org/consent/${dataDomainScriptId}/otSDKStub.js`;
  const shouldHaveAuthorMeta = authors && ['article', 'evergreen', 'longform'].includes(pageType);

  return (
    <NextHead>
      <title>{formatAmpersand(title)}</title>
      <meta
        name='msapplication-TileColor'
        content='#ffc40d'
      />
      <meta
        name='theme-color'
        content={commonColors.surfaceWhite}
      />
      <meta
        name='description'
        content={description}
      />
      {shouldHaveAuthorMeta && (
        <meta
          name='author'
          content={authors}
        />
      )}
      <meta
        name='keywords'
        content={keywords}
      />
      <meta
        property='og:url'
        content={link}
      />
      <meta
        property='og:type'
        content='article'
      />
      <meta
        property='og:title'
        content={socialTitle}
      />
      <meta
        property='og:description'
        content={socialDescription}
      />
      <meta
        property='og:site_name'
        content={siteName}
      />
      <meta
        property='og:image'
        content={image}
      />
      <meta
        property='og:width'
        content='1024'
      />
      <meta
        property='og:height'
        content='683'
      />
      <meta
        name='twitter:card'
        content='summary_large_image'
      />
      <meta
        name='twitter:title'
        content={socialTitle}
      />
      <meta
        name='twitter:description'
        content={socialDescription}
      />
      <meta
        name='twitter:image'
        content={imageTwitter || image}
      />
      <meta
        name='twitter:url'
        content={link}
      />
      <meta
        property='fb:pages'
        content='56501027948'
      />
      <meta
        name='robots'
        content={getRobotsContent()}
      />
      {router.route === '/advanced-search' && (
        <meta
          name='robots'
          content='noindex, nofollow'
        />
      )}
      {widgetCss && (
        <style type='text/css'>
          {`
            ${widgetCss}
          `}
        </style>
      )}
      {/* <link
        rel='preconnect'
        href='https://www.googletagmanager.com'
        crossOrigin=''
      /> */}
      <link
        rel='preconnect'
        href='https://www.google-analytics.com'
        crossOrigin=''
      />
      <link
        rel='preconnect'
        href='https://www.googletagservices.com'
        crossOrigin=''
      />
      <link
        rel='preconnect'
        href='https://ad.doubleclick.net'
        crossOrigin=''
      />
      <link
        rel='preconnect'
        href='https://static.doubleclick.net'
        crossOrigin=''
      />
      <link
        rel='preconnect'
        href='https://securepubads.g.doubleclick.net'
        crossOrigin=''
      />
      <link
        rel='icon'
        href='/icons/favicons/favicon.ico'
      />
      <link
        rel='canonical'
        href={canonical}
      />
      {siteName === 'Fortune Recommends' && (
        <link
          rel='preload'
          href='https://fortune.com/fonts/GraphikCondensed-Regular-Web.woff2'
          as='font'
          crossOrigin=''
          type='font/woff2'
        />
      )}
      {redVenture && (
        <link
          rel='preconnect'
          href='https://static.myfinance.com'
          crossOrigin=''
        />
      )}
      {useBinanceWidget && (
        <link
          rel='preconnect'
          href='https://public.bnbstatic.com/'
          crossOrigin=''
        />
      )}
      {rssFeedLink && (
        <link
          rel='alternate'
          type='application/rss+xml'
          title='Fortune RSS'
          href={rssFeedLink}
        />
      )}
      {faqSchema && (
        <script
          id='faq-json-schema'
          type='application/ld+json'
          dangerouslySetInnerHTML={{ __html: faqSchema }}
        />
      )}
      {jsonLdSchemaFinal && (
        <script
          id='json-schema'
          type='application/ld+json'
          dangerouslySetInnerHTML={{ __html: jsonLdSchemaFinal }}
        />
      )}
      <script>window.dataLayer = window.dataLayer || []</script>
      {oneTrustReload && (
        <script
          id='OneTrustUserObject'
          type='text/javascript'
        >
          var OneTrust = {setUserObjScriptVal(oneTrustUserObj)};
        </script>
      )}
      {oneTrustReload && (
        <>
          <script
            async
            src={oneTrustScript}
            data-document-language='true'
            type='text/javascript'
            data-domain-script={dataDomainScriptId}
          />
          <script type='text/javascript'>{function OptanonWrapper() {}}</script>
        </>
      )}
      {isMounted && (
        <>
          <script>{"window.grumi = { key: 'f4c1124c-8d38-477a-ad8d-f637a3802f32' }"}</script>
          <script
            src='//rumcdn.geoedge.be/f4c1124c-8d38-477a-ad8d-f637a3802f32/grumi-ip.js'
            async
          />
        </>
      )}
      {oneTrustConsent && segmentWriteKey && isMounted && (
        <script
          id='segment'
          dangerouslySetInnerHTML={{
            __html: `!function(){
              var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","screen","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware","register"];analytics.factory=function(e){return function(){if(window.analytics.initialized)return window.analytics[e].apply(window.analytics,arguments);var i=Array.prototype.slice.call(arguments);if(["track","screen","alias","group","page","identify"].indexOf(e)>-1){var c=document.querySelector("link[rel='canonical']");i.push({__t:"bpc",c:c&&c.getAttribute("href")||void 0,p:location.pathname,u:location.href,s:location.search,t:document.title,r:document.referrer})}i.unshift(e);analytics.push(i);return analytics}};for(var i=0;i<analytics.methods.length;i++){var key=analytics.methods[i];analytics[key]=analytics.factory(key)}analytics.load=function(key,i){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=i};analytics._writeKey='${segmentWriteKey}';;analytics.SNIPPET_VERSION="5.2.0"; 
            analytics.load('${segmentWriteKey}');
            analytics.page("${pageTitle.current}", {
              "content_authors": "${authors}",
              "content_publication_date": "${dateGmt}",
              "content_section": "${primarySection}",
              "document_referrer": "${referrer.current}",
              "fuid": "${fuidCookie}",
              "ga_client_id": "${gacidCookie}",
              "gtm_referrer": "${gtmReferrerCookie}",
              "page_content_type": "${pageType}",
              "page_platform": "${subDomain}",
              "piano_user_id": "${pianoUserIdCookie}",
              "piano_visitor_id": "${parsedPianoVisitorId}",
              "post_id": "${prefixedDatabaseId || databaseId}",
              "premium_category": "${premiumCategory}",
              "query_string": "${queryParamsRef.current || campaignUrlCookie}",
              "tags_array": "${tagNames}"
            });
            }}();`,
          }}
        />
      )}
      {/* {gtmAuth && gtmPreview && (
        <script
          id='gtm'
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=${gtmAuth}&gtm_preview=${gtmPreview}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-N68KRG3');`,
          }}
        />
      )} */}
      <link
        rel='dns-prefetch'
        href='https://c.amazon-adsystem.com'
      />
      <link
        rel='preconnect'
        href='https://c.amazon-adsystem.com'
        crossOrigin=''
      />
      <script
        async
        src='https://c.amazon-adsystem.com/aax2/apstag.js'
      />
    </NextHead>
  );
};

export default Head;
