import { useEffect, useState } from 'react';
import cookies from 'js-cookie';
import {
  CCPAConsent,
  CCPAConsentOptions,
  DataProtection,
  DataProtectionOptions,
  GDPRConsent,
} from 'hooks/dataProtection';

interface cookieAttributesObject {
  [key: string]: string;
}

interface activeConsentGroups {
  [key: string]: string;
}

const getConsentCookie = (cookieString: string): cookieAttributesObject => {
  const cookieAttributesArray = cookieString.split('&');
  const cookieAttributes = cookieAttributesArray.reduce((acc: cookieAttributesObject, cur) => {
    const [key, value] = cur.split('=');
    acc[key] = value;
    return acc;
  }, {});
  return cookieAttributes;
};

const getActiveConsentGroups = (consentCookieObject: cookieAttributesObject): activeConsentGroups => {
  const activeConsentGroups = consentCookieObject.groups.split(',');
  const consentGroups = activeConsentGroups.reduce((acc: activeConsentGroups, cur) => {
    const [key, value] = cur.split(':');
    acc[key] = value;
    return acc;
  }, {});
  return consentGroups;
};

/*
 Data protection applies only to specific countries.
 Currently GDPR for EU and CCPA for CA inside US
 */
const isDataProtectionApplied = (dataProtectionCookie: string | undefined) => {
  if (dataProtectionCookie && DataProtectionOptions.NON_EU_NON_US === dataProtectionCookie) {
    return false;
  }
  return true;
};

/*
 CCPA applies only to CA inside US
 */
const isCCPAApplied = (dataProtectionCookie: string | undefined, ccpaCookie: string | undefined) => {
  if (dataProtectionCookie && DataProtectionOptions.US === dataProtectionCookie && ccpaCookie) {
    return true;
  }
  return false;
};

/*
 GDPR applies only to EU countries
 */
const isGDPRApplied = (gdprCookie: string | undefined) => !!gdprCookie;

/*
 Until user explicitly opts-out for ads, cookie usage is accepted
 */
const hasCCPAConsent = (ccpaCookie: string | undefined) =>
  CCPAConsentOptions.OPT_IN === ccpaCookie || CCPAConsentOptions.NOT_APPLICABLE === ccpaCookie;

/*
 Needs to contain level 3 for advertising
 */
const hasGDPRConsent = (gdprCookie: string | undefined) =>
  (gdprCookie ? (gdprCookie.match(/\d+/g) || []).map(Number).includes(3) : false);
/*
 Decides if user opted-in for advertising, depending on their data protection laws specific to the region
 */
export const getConsentFromCookie = (): boolean | null => {
  const dataProtectionCookie = cookies.get('notice_behavior') as DataProtection;
  const gdprCookie = cookies.get('cmapi_cookie_privacy') as GDPRConsent;
  const ccpaCookie = cookies.get('usprivacy') as CCPAConsent;

  if (!isDataProtectionApplied(dataProtectionCookie)) {
    return true;
  }

  if (isCCPAApplied(dataProtectionCookie, ccpaCookie)) {
    if (hasCCPAConsent(ccpaCookie)) {
      return true;
    }
    return false;
  }

  if (isGDPRApplied(gdprCookie)) {
    if (hasGDPRConsent(gdprCookie)) {
      return true;
    }
    return false;
  }

  return null;
};

/**
 * Hook for checking the user's advertising consent. Hook value updates in case of changes.
 */

export function useAdvertisingConsent() {
  const [consent, setConsent] = useState<boolean | null>(null);
  const [optanonConsent, setOptanonConsent] = useState<string | undefined>(cookies.get('OptanonConsent'));

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      setConsent(true);
      return () => {};
    }

    window.setInterval(() => {
      setOptanonConsent(cookies.get('OptanonConsent'));
    }, 100);

    if (optanonConsent && optanonConsent.includes('groups')) {
      const consentCookieObject: cookieAttributesObject = getConsentCookie(optanonConsent);
      const activeConsentGroups: activeConsentGroups = getActiveConsentGroups(consentCookieObject);
      const consentGroupTotal: number = Object.values(activeConsentGroups).reduce((acc, cur) => {
        if (parseInt(cur, 10) === 1) {
          return acc + 1;
        }
        return acc;
      }, 0);
      if (consentGroupTotal >= 5) {
        setConsent(true);
      } else {
        setConsent(false);
      }
    }
    return () => {};
  }, [consent, optanonConsent]);

  return consent;
}
