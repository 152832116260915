import getCommonTestId from 'constants/testsIds/common';
import AdSlot from './AdSlot';
import { RightRailSimpleAd } from './Styles';

interface RightRailSimpleProps {
  id: number;
  className?: string;
  page?: string;
  dataCy?: string;
}

const RightRailSimple = ({ id, className, page, dataCy = getCommonTestId('RIGHT_RAIL_AD') }: RightRailSimpleProps) => (
  <RightRailSimpleAd
    className={`${className || ''}`}
    $page={page}
    data-cy={dataCy}
  >
    <AdSlot
      placementName='RightRailFlex'
      index={id + 1}
      slotId={`RightRailFlex${id}`}
    />
  </RightRailSimpleAd>
);

export default RightRailSimple;
