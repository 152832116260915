/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { trackEvent } from 'services/Gtm/functions';
import { EventData } from 'services/Gtm/entities';
import { ButtonSizeStyling } from 'components/Globals/Base/Button/types';
import { AnchorHTMLAttributes, DetailedHTMLProps, useEffect, useState } from 'react';
import cx from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { useAnalyticsData } from 'hooks/useAnalyticsData';
import { LinkTypes } from './types';
import * as S from './LinkStyles';

export interface CustomLinkProps extends DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
  dataCy?: string;
  trackingData?: EventData;
  // TODO: the ariaLabel prop can be removed and sent directly through aria-label
  ariaLabel?: string;
  type?: keyof typeof LinkTypes;
  size?: { default: keyof ButtonSizeStyling; [key: string]: keyof ButtonSizeStyling };
  center?: boolean;
  hasRightArrow?: boolean;
  hasLeftArrow?: boolean;
  onKeyDown?: () => void;
  onMouseEnter?: () => void;
}

// make full reloads when changing a route, in order to disable SPA
export const changeRoute = (url: string) => {
  window.location.href = url;
  return Promise.resolve(true);
};

export const parseHref = (href?: string) => {
  const whitelistedEnvironments = ['dev', 'qa', 'staging', 'feature'];
  const currentEnvironment = process.env.ENVIRONMENT;

  // if the app is in development mode or if it's built for the remote dev and qa envs, preserve the href
  if (
    process.env.NODE_ENV === 'development' ||
    (currentEnvironment && whitelistedEnvironments.includes(currentEnvironment))
  ) {
    return href;
  }

  if (!href) {
    return '';
  }

  if (href.startsWith('/')) {
    return `https://fortune.com${href}`;
  }

  return href;
};

/**
 * Wrapper component around next/link that allows passing a className (which is often needed on links).
 * This component also handles tracking on clicks.
 */
const CustomLink = ({
  href,
  target = '_self',
  ariaLabel = '',
  children,
  className = '',
  dataCy,
  trackingData,
  type,
  size,
  center,
  hasRightArrow = true,
  hasLeftArrow = false,
  ...rest
}: CustomLinkProps) => {
  const parsedHref = parseHref(href);

  const handleClick = () => {
    if (trackEvent && trackingData) trackEvent(trackingData);
  };

  return (
    <S.Link
      $type={type || 'primary'}
      $size={size}
      $center={center}
      $hasRightArrow={hasRightArrow}
      $hasLeftArrow={hasLeftArrow}
      href={parsedHref}
      target={target}
      className={className}
      data-cy={dataCy}
      onClick={handleClick}
      {...rest}
      {...(ariaLabel ? { 'aria-label': ariaLabel } : {})}
      suppressHydrationWarning
    >
      {children}
    </S.Link>
  );
};

function filterPage(path: string): boolean {
  /* @description - url we are tracking to test */
  /* 
    Example urls:
      https://oc.brcclx.com/t?lid=26687034&tid=[dynamic]post-id[/dynamic]
      https://www.cardratings.com/bestcards/featured-credit-cards?shnq=5048341&src=692824&var2=[dynamic]post-id[/dynamic]
      https://issa.sjv.io/c/5196544/919499/12162?tag.subid=[dynamic]post-id[/dynamic]
      https://secure.money.com/pr/zf80c9147822?s1=[dynamic]post-id[/dynamic]
      https://r.financebuzz.com/aff_c?offer_id=8560&aff_id=1940&source=[dynamic]post-id[/dynamic]
      https://api.fintelconnect.com/t/l/665f84d9e7749d001b74af21?acid=[dynamic]post-id[/dynamic]
      https://www.creditsaint.com/enter/00e903869a98b935088abef62f0184fd?subid=[dynamic]post-id[/dynamic]
      https://try.thecreditpros.com/partner-home/?PPCPN=844-546-8642&offerID=91&affID=3722&phone=&guid1=&utm_medium=affiliate&utm_source=PayPerSale&utm_campaign=AF-ShortFormLander_d&Affiliate_Sub_ID_1__c=&conaffid=&t=102636df9414d854a83b56e096e677&aff_click_id=&r=3785728&aff_sub2=102636df9414d854a83b56e096e677
      https://fiona.com/partner/fortune-bridge/savings?bridge_partner=sofi-savings-direct&tag.sub_id=[dynamic]post-id[/dynamic]
      https://getstarted.avocademy.com/pages/fortune?sca_ref=5967926.PLEEmkgmy8&utm_source=fortune&sca_source=[dynamic]post-id[/dynamic]
  */

  try {
    const { origin, searchParams, href } = new URL(path);
    if (origin.includes('https://www.cardratings.com')) {
      // QuinStreet
      const cardRatingsShnqs = [3006090, 5048341];
      if (searchParams.get('shnq') && cardRatingsShnqs.includes(Number(searchParams.get('shnq')))) {
        return true;
      }
    }
    if (origin.includes('https://oc.brcclx.com')) {
      // Red Ventures
      const redVenturesLids = [26687034];
      if (searchParams.get('lid') && redVenturesLids.includes(Number(searchParams.get('lid')))) {
        return true;
      }
    }
    if (origin.includes('https://secure.money.com')) {
      // Money Group
      const rvParams = ['zf80c9147822'];
      if (rvParams.some((paramString) => href.includes(paramString))) {
        return true;
      }
    }
    if (origin.includes('https://issa.sjv.io')) {
      // Impact
      const impactParams = ['5196544/919499/12162'];
      if (impactParams.some((paramString) => href.includes(paramString))) {
        return true;
      }
    }
    if (origin.includes('https://api.fintelconnect.com')) {
      // Fintel Connect
      const fintelParams = ['665f84d9e7749d001b74af21'];
      if (fintelParams.some((paramString) => href.includes(paramString))) {
        return true;
      }
    }
    if (origin.includes('https://www.creditsaint.com')) {
      // Credit Saint
      const csParams = ['00e903869a98b935088abef62f0184fd'];
      if (csParams.some((paramString) => href.includes(paramString))) {
        return true;
      }
    }
    if (origin.includes('https://fiona.com')) {
      // Engine
      const engineParams = ['fortune-bridge'];
      const bridgePartnerParams = ['sofi-savings-direct'];
      if (
        engineParams.some((paramString) => href.includes(paramString)) &&
        bridgePartnerParams.some((paramString) => href.includes(paramString))
      ) {
        return true;
      }
    }
    if (origin.includes('https://getstarted.avocademy.com')) {
      // Avocademy
      const scaRefParams = ['5967926.PLEEmkgmy8'];
      if (scaRefParams.some((paramString) => href.includes(paramString))) {
        return true;
      }
    }
    if (origin.includes('https://try.thecreditpros.com')) {
      // Credit Pros
      const ppcpnIds = ['844-546-8642'];
      const offerIds = ['91'];
      const affIds = ['3722'];
      if (
        ppcpnIds.some((paramString) => href.includes(paramString)) &&
        offerIds.some((paramString) => href.includes(paramString)) &&
        affIds.some((paramString) => href.includes(paramString))
      ) {
        return true;
      }
    }
    if (origin.includes('https://r.financebuzz.com')) {
      // Launch Potato
      const financeBuzzOfferIds = [
        11395, 20109, 16633, 19376, 20955, 14935, 17989, 17863, 17593, 12911, 13435, 20907, 20871, 16541, 19777, 18096,
        20660, 18098, 16560, 8560,
      ];
      if (searchParams.get('offer_id') && financeBuzzOfferIds.includes(Number(searchParams.get('offer_id')))) {
        return true;
      }
    }
    return false;
  } catch (e) {
    return false;
  }
}

const generateUuid = () => uuidv4();

const CustomLinkWithRedirect = (props: CustomLinkProps) => {
  const { children, type, rel, target, className, dataCy, href } = props;

  const [clickUUID, setClickUUID] = useState<string | null>(generateUuid());
  const [url, setUrl] = useState<string | null>(null);

  const { data } = useAnalyticsData(href);

  useEffect(() => {
    /*
     * @description - TODO use url parser
     */
    if (!href) return;
    setUrl(
      `/recommends/redirect/?click-data=${JSON.stringify({ ...data, clickUUID })}&origin=${encodeURIComponent(href)}`,
    );
  }, [clickUUID, data, href]);

  return (
    <CustomLink
      onClick={() => {
        setClickUUID(generateUuid());
      }}
      key={generateUuid()}
      href={url || (href as string)}
      type={type || 'primary'}
      target={target}
      rel={rel}
      className={`${className} styled-custom-redirect-link`}
      data-cy={dataCy}
      aria-disabled
    >
      {children}
    </CustomLink>
  );
};

const WithRedirect = (props: CustomLinkProps) => {
  const { children, href, className, type } = props;
  const willRedirect = filterPage(href!);

  if (willRedirect) {
    return (
      <CustomLinkWithRedirect
        href={href}
        {...props}
      />
    );
  }

  return (
    <CustomLink
      {...props}
      className={cx(className, { 'styled-custom-link': type })}
    >
      {children}
    </CustomLink>
  );
};

export default WithRedirect;
