import React from 'react';
import getAdsTestId from 'constants/testsIds/ads';
import AdSlot from './AdSlot';
import { InStreamAd, AdsCenteredDiv } from './Styles';

interface InStreamProps {
  id: number;
  className?: string;
  renderAds: boolean;
  dataCy?: string;
  pageType?: string;
}

const InStream: React.FC<InStreamProps> = ({ id, className, renderAds, dataCy, pageType }: InStreamProps) => {
  const inContentPageTypes = ['article-v2', 'company-v2'];
  if (renderAds) {
    return (
      <AdsCenteredDiv>
        <InStreamAd className={`${className || ''}`}>
          <AdSlot
            pageType={pageType}
            placementName={pageType && inContentPageTypes.includes(pageType) ? 'InContent' : 'InStream'}
            index={id + 1}
            slotId={`InStream${id}`}
            dataCy={`${getAdsTestId('IN_STREAM')}-${id}`}
          />
        </InStreamAd>
      </AdsCenteredDiv>
    );
  }

  return <div data-cy={dataCy} />;
};

export default InStream;
