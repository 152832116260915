/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access,  @typescript-eslint/no-unsafe-assignment */
import React, { CSSProperties, KeyboardEvent } from 'react';
import HtmlToReact, { ProcessNodeDefinitionsInstance, ParserInstance } from 'html-to-react';
import Divider from 'components/Divider';
import Gallery from 'components/Gallery';
import IFrame from 'components/Iframe';
import { NextCustomImage } from 'components/Image';
import Link from 'components/Globals/Base/Link';
import Paywall from 'components/Paywall';
import Regwall from 'components/Regwall';
import ResponsiveTable from 'components/ResponsiveTable';
import Script from 'next/script';
import VideoEmbed from 'components/VideoEmbed';
import parse from 'style-to-object';
import { bkptVal } from 'styles/globals';
import ImageTextWrapper from 'components/recommends/ImageTextWrapper';
import ExploreProducts from 'components/ExploreProducts';
import Tabs from 'components/Globals/Tabs';
import setUtmParams from 'utils/adsUtmParams';
import { v4 as uuidv4 } from 'uuid';

type Props = {
  html: string | undefined;
};

/* This class represents the custom parsed element we are creating when we parse the element 
coming from Wordpress and convert it to the corresponding React element. The properties on this 
class can be added to and extended as needed. */
interface ParsedHTMLElement extends JSX.Element {
  attribs: {
    class: string;
    src: string;
    style?: string;
    href: string;
    width: string;
    height: string;
    alt: string;
    target?: string;
    [key: `data-${string}`]: string;
    children: ParsedHTMLElement[];
  };
  children: ParsedHTMLElement[];
  data: string;
  name: string;
  next: ParsedHTMLElement;
  type: string;
  parent: ParsedHTMLElement;
}

const processNodeDefinitions: ProcessNodeDefinitionsInstance = new HtmlToReact.ProcessNodeDefinitions(React);

const aTagInstruction = {
  processNode: (node: ParsedHTMLElement, children: ParsedHTMLElement[]) => {
    const { href, class: className, style, ...props } = node.attribs;
    const styleObj = style ? { style: parse(style) as CSSProperties } : {};
    let isExternal;
    try {
      isExternal = !/https:\/\/(?:.*\.)?fortune\.com/.test(new URL(href, 'https://fortune.com').origin);
    } catch (e) {
      if (process.env.NODE_ENV === 'development') {
        console.error('Invalid url found while parsing node:');
        console.error(node);
      }
    }
    return (
      <Link
        href={href || ''}
        aria-label={`Go to ${href}`}
        rel={isExternal ? 'noopener' : undefined}
        target={isExternal ? '_blank' : undefined}
        className={className}
        {...styleObj}
        {...props}
      >
        {children}
      </Link>
    );
  },
  replaceChildren: false,
  shouldProcessNode: (node: ParsedHTMLElement) => node.name === 'a',
};

/*
  The content of the image block is variable.
  Because of that, we need to search for the image tag on all nesting levels.
 */
const findImageTag = (children: ParsedHTMLElement[]): ParsedHTMLElement | null => {
  let img: ParsedHTMLElement | null = null;
  children.forEach((child) => {
    // @ts-ignore
    if (child.name === 'img') {
      img = child;
    }
    if (child.children) {
      // @ts-ignore
      const result = findImageTag(child.children);
      if (result !== null) {
        img = result;
      }
    }
  });
  return img;
};

/*
  Puts a maximum width to the parent block of the image.
  The maximum width will not be crossed by the image on any resolution
 */
const parentImgTagInstruction = {
  processNode: (node: ParsedHTMLElement, children: ParsedHTMLElement[]) => {
    // @ts-ignore
    const image = findImageTag(node.children);

    // @ts-ignore
    if (image && image.attribs && image.attribs.width) {
      // @ts-ignore
      const { width, src } = image.attribs;
      return (
        <div
          className='wp-block-image'
          style={{ margin: 'auto', maxWidth: `${width}px` }}
          key={src}
        >
          {children}
        </div>
      );
    }
    return <div className='wp-block-image'>{children}</div>;
  },
  replaceChildren: false,
  shouldProcessNode: (node: ParsedHTMLElement) =>
    // @ts-ignore
    !!(node.attribs && node.attribs.class && node.attribs.class.includes('wp-block-image')),
};

/*
  Maps the classic <img> tag to a Next.js Image component
 */
const imgTagInstruction = {
  processNode: (node: ParsedHTMLElement) => {
    const props = node.attribs;
    const parentClass = node.parent.attribs.class;
    return (
      <ImageTextWrapper
        className={parentClass || ''}
        width={props.width}
        height={props.height}
      >
        <NextCustomImage
          key={props.src}
          src={`${props['data-src'] || props.src}`}
          alt={props.alt}
          {...(props.width && props.height ?
            { height: Number(props.height), width: Number(props.width) } :
            { height: '683', objectFit: 'contain', width: '1024' })}
        />
      </ImageTextWrapper>
    );
  },
  replaceChildren: false,
  shouldProcessNode: (node: ParsedHTMLElement) => node.name === 'img',
};

const videoParentTagInstructions = {
  processNode: (node: ParsedHTMLElement) => {
    const props: any = node.attribs;
    const hasStyle: string = props.style;
    const videoSrc = node.children[0].attribs.src;
    const divWidth = '320';

    if (!hasStyle) {
      return (
        <div style={{ margin: 'auto', maxWidth: `${divWidth}px` }}>
          <VideoEmbed
            src={videoSrc}
            width='100%'
            height=''
            key={videoSrc}
          />
        </div>
      );
    }
    return (
      <VideoEmbed
        src={videoSrc}
        width='100%'
        height='100%'
        key={videoSrc}
      />
    );
  },
  replaceChildren: false,
  shouldProcessNode: (node: ParsedHTMLElement) => node.name === 'figure' && node.attribs.class === 'wp-block-video',
};

const vodVideoDivInstruction = {
  processNode: (node: ParsedHTMLElement, children: ParsedHTMLElement[]) => (
    <div
      className='vodvideocontainer'
      key='vodvideocontainer'
    >
      {children}
    </div>
  ),
  replaceChildren: false,
  shouldProcessNode: (node: ParsedHTMLElement) => node?.attribs?.class === 'vodvideocontainer',
};

const iframeTagInstruction = {
  processNode: (node: ParsedHTMLElement) => {
    const hasResponsiveHeightScript =
      node.next && node.next.type === 'script' && node.next.children[0].data.includes('datawrapper-height');

    const props: typeof node.attribs & { [key: string]: any } = node.attribs;

    let stylesHeight: undefined | string = props?.height;
    let stylesWidth: undefined | string = props?.width;

    if (props?.style && props.style.includes('height')) {
      const stylesArraySplitByHeight: string[] = props.style.replace(' ', '').split('height:');
      stylesHeight = stylesArraySplitByHeight[1].split(';')[0];
    }

    if (props?.style && props.style.includes('width')) {
      const stylesArraySplitByWidth: string[] = props.style.replace(' ', '').split('width:');
      stylesWidth = stylesArraySplitByWidth[1].split(';')[0];
      stylesWidth = stylesWidth !== '0' ? stylesWidth : undefined;
    }

    return (
      <IFrame
        ariaLabel={props['aria-label']}
        id={props.id}
        frameBorder={props.frameborder}
        hasResponsiveHeightScript={hasResponsiveHeightScript}
        height={stylesHeight || '600'}
        loading='lazy'
        scrolling={props.scrolling}
        src={props.src}
        style={{ border: 'none' }}
        title={props.title}
        width={stylesWidth || '100%'}
        key={props.src}
        className={props.class}
      >
        {props.children}
      </IFrame>
    );
  },
  replaceChildren: false,
  shouldProcessNode: (node: ParsedHTMLElement) => node.name === 'iframe',
};

const hrTagInstruction = {
  processNode: () => <Divider />,

  replaceChildren: false,
  // @ts-ignore
  shouldProcessNode: (node: ParsedHTMLElement) => node.name === 'hr',
};

const tableTagParentInstructions = {
  processNode: (node: ParsedHTMLElement, children: ParsedHTMLElement[]) => (
    <div>
      <div className={`table-container ${node.attribs.class}`}>{children.find((child) => child.type === 'table')}</div>
      {children.filter((child) => child.type !== 'table')}
    </div>
  ),
  replaceChildren: false,
  shouldProcessNode: (node: ParsedHTMLElement) =>
    node.name === 'div' &&
    node.attribs?.class?.includes('wp-block-getwid-table') &&
    !node.attribs?.class?.includes('-widget') &&
    node.children.find((child) => child.name === 'table'),
};

const paywallDivInstructions = {
  processNode: (node: ParsedHTMLElement, children: JSX.Element) => <Paywall>{children}</Paywall>,
  replaceChildren: false,
  shouldProcessNode: (node: ParsedHTMLElement) =>
    // @ts-ignore
    !!(node.name === 'div' && node.attribs?.class && node.attribs.class === 'paywall'),
};

const regwallDivInstructions = {
  processNode: (node: ParsedHTMLElement, children: JSX.Element) => <Regwall>{children}</Regwall>,
  replaceChildren: false,
  shouldProcessNode: (node: ParsedHTMLElement) =>
    // @ts-ignore
    !!(node.name === 'div' && node.attribs?.class && node.attribs.class === 'regwall'),
};

const photoGalleryInstruction = {
  processNode: (node: ParsedHTMLElement, children: ParsedHTMLElement[]) => {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    let childrenVar = children;
    const hasMissingDimensions = children.some((chi) => {
      const imgElement = chi?.props?.children?.[0];
      return Number.isNaN(+(imgElement?.props?.width || NaN)) || Number.isNaN(+(imgElement?.props?.height || NaN));
    });
    if (hasMissingDimensions) {
      const firstElementWithDimensions = children.find((chi) => {
        const imgElement = chi?.props?.children?.[0];
        return (
          imgElement?.props &&
          !Number.isNaN(+(imgElement.props.width || NaN)) &&
          !Number.isNaN(+(imgElement.props.height || NaN))
        );
      });

      const { width = '1440', height = '960' } = firstElementWithDimensions?.props?.children?.[0]?.props || {};

      childrenVar = children.map((chi) => {
        if (!chi?.props?.children) return chi;
        return {
          ...chi,
          props: {
            ...chi.props,
            children: (chi?.props?.children as JSX.Element[])?.map((ch: JSX.Element) => {
              if (typeof ch === 'string' || !ch.props) return ch;
              return { ...ch, props: { ...ch.props, height, width } };
            }),
          },
        };
      });
    }

    if (childrenVar[0] && childrenVar[0].props && childrenVar[0].props.className === 'blocks-gallery-grid') {
      const galleryElements: HTMLElement[] = childrenVar[0]?.props?.childrenVar;
      return galleryElements ? (
        <Gallery>
          {
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
            galleryElements.map((child) => child.props.childrenVar)
          }
        </Gallery>
      ) : (
        <div />
      );
    }
    return <Gallery>{childrenVar.filter((child) => typeof child !== 'string')}</Gallery>;
  },
  replaceChildren: false,
  shouldProcessNode: (node: ParsedHTMLElement) =>
    // @ts-ignore
    !!(node.name === 'figure' && node.attribs?.class && node.attribs.class.includes('wp-block-gallery')),
};

const twitterScriptInstructions = {
  processNode: (node: ParsedHTMLElement) => {
    const newSrc = `${node.attribs.src}?t=${Date.now()}`;

    return (
      <Script
        key={Math.random()}
        id={newSrc}
        src={newSrc}
        onLoad={() => {
          const scriptElements = document.querySelectorAll(`script[id^='${node.attribs.src}']:not([id='${newSrc}'])`);
          if (scriptElements[0]) scriptElements[0].remove();
        }}
      >
        {node.attribs.children}
      </Script>
    );
  },
  replaceChildren: false,
  shouldProcessNode: (node: ParsedHTMLElement) =>
    node &&
    node.name === 'script' &&
    node.attribs &&
    node.attribs.src &&
    node.attribs.src.indexOf('https://platform.twitter.com/widgets.js') > -1,
};

const styleScriptInstructions = {
  processNode: (node: ParsedHTMLElement) => {
    if (node.name === 'style') {
      return (
        <style
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: node.children[0]?.data }}
        />
      );
    }
    if (node.children.length) {
      return <Script id='inline-script'>{node.children[0]?.data}</Script>;
    }
    return (
      <Script
        key={node.attribs.src}
        src={`${node.attribs.src}`}
      />
    );
  },
  replaceChildren: false,
  shouldProcessNode: (node: ParsedHTMLElement) => node.name === 'style' || node.name === 'script',
};

const newLineRemovalInstruction = {
  processNode: () => {},
  shouldProcessNode: (node: ParsedHTMLElement) => node.data === '\n',
};

const productCardAccordionInstructions = {
  processNode: (node: ParsedHTMLElement, children: JSX.Element[]) => {
    const uniqueID = uuidv4();
    const desktopBreakpoint = bkptVal.md * 16;
    const accordionContent = children.map((childElement) => {
      const childProps = { ...childElement?.props } as { [key: string]: string | ((e: KeyboardEvent) => void) };
      if (childElement && typeof childElement === 'object' && childProps) {
        // the wordpress block used for the product cards has some "[ID]" placeholder values set for some attributes that should be replaced with unique identifiers
        // replacing these values is necessary because we need to ensure uniqueness of radio button ids and group names in case there are multiple cards on the same page
        ['id', 'htmlFor', 'name', 'aria-labelledby', 'aria-controls'].forEach((prop) => {
          const propValue = childProps[prop] as string;
          if (propValue?.includes('[ID]')) {
            childProps[prop] = propValue.replace('[ID]', uniqueID);
          }
        });
        if (childProps.type === 'radio' && childProps.checked !== undefined) {
          delete childProps.checked;
          childProps.defaultChecked = true as any;
        }
        if (childProps.role === 'button') {
          // ensuring proper keyboard navigation
          childProps.onKeyDown = (e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              const radioButton = document.getElementById((e.target as HTMLLabelElement)?.htmlFor) as HTMLInputElement;
              // users shouldn't be able to close the tabs on desktop
              if (radioButton && !(radioButton.checked && window?.innerWidth > desktopBreakpoint)) {
                radioButton.checked = !radioButton.checked;
              }
            }
          };
          childProps.onClick = (e) => {
            const labelElement = (e.target as HTMLLabelElement).closest('label');
            const radioButton = document.getElementById(
              (labelElement as HTMLLabelElement)?.htmlFor,
            ) as HTMLInputElement;

            if (radioButton?.checked && window?.innerWidth < desktopBreakpoint) {
              e.preventDefault();
              radioButton.checked = false;
            }
          };
        }
        return { ...childElement, props: childProps };
      }
      return childElement;
    });
    return accordionContent;
  },
  replaceChildren: true,
  shouldProcessNode: (node: ParsedHTMLElement) =>
    node.name === 'section' && node?.attribs?.class?.indexOf('card-accordion') > -1,
};

const detailsSummaryInstructions = {
  processNode: (node: ParsedHTMLElement, children: ParsedHTMLElement[]) => {
    const detailsSummaryContent = children.map((childElement) => {
      const props = childElement?.props;
      if (childElement.type === 'summary' && props?.children) {
        return { ...childElement, props: { ...props, children: <h3>{props.children}</h3> } };
      }
      return childElement;
    });

    return detailsSummaryContent;
  },
  replaceChildren: true,
  shouldProcessNode: (node: ParsedHTMLElement) => node?.attribs?.class?.includes('wp-block-details'),
};

const responsiveTableInstructions = {
  processNode: (node: ParsedHTMLElement, children: ParsedHTMLElement[]) => {
    if (!children) return null;

    const table = children?.find((el) => typeof el === 'object' && el.type === 'table')?.props
      .children as JSX.Element[];

    const headers: JSX.Element[] = table?.find((el) => typeof el === 'object' && el.type === 'thead')?.props?.children
      ?.props?.children;

    const rows: JSX.Element[] = table?.find((el) => typeof el === 'object' && el.type === 'tbody')?.props?.children;

    if (!headers || !rows) return null;

    const headerValues = headers
      ?.filter((el) => typeof el === 'object' && (el.type === 'td' || el.type === 'th'))
      ?.map((cell) => cell?.props?.children as string);

    const captionValue: string = children?.find((el) => typeof el === 'object' && el.type === 'figcaption')?.props
      ?.children;

    const rowValues = rows.map((row: JSX.Element) => {
      const cells: JSX.Element[] = (row?.props?.children as JSX.Element[])?.filter(
        (el) => typeof el === 'object' && (el.type === 'td' || el.type === 'th'),
      );
      return cells?.map((cell) => cell?.props?.children as string);
    });

    const hasLink = rows.some((row) => {
      const cells = row.props.children as JSX.Element[];
      const lastCell = cells[cells.length - 1];
      return lastCell.props.children?.props?.href;
    });

    return (
      <ResponsiveTable
        headers={headerValues}
        rows={rowValues}
        caption={captionValue}
        hasLink={hasLink}
      />
    );
  },
  shouldProcessNode: (node: ParsedHTMLElement) =>
    node.name === 'figure' &&
    node?.attribs?.class?.includes('optimized-table-widget') &&
    node.children.find((child) => child.name === 'table'),
};

const exploreOurProductsInstructions = {
  processNode: (node: ParsedHTMLElement, children: JSX.Element[]) => {
    try {
      const title = children.map(
        (el) => (el.props.children as JSX.Element[]).find((child) => child.type === 'h2')?.props.children as string,
      )[0];

      const liItems = children
        .map((el) =>
          (
            (el.props.children as JSX.Element[]).find((child) => child.type === 'ul')?.props.children as JSX.Element[]
          ).filter((lists) => lists.type === 'li'))
        .flat();

      // eslint-disable-next-line max-len
      const textItems = liItems
        .map((el) => el?.props?.children as JSX.Element[])
        .flat()
        .map((child) => child?.props?.children as string[])
        .flat();
      const hrefItems = liItems.map((el) => (el?.props.children as JSX.Element)?.props?.href as string[]).flat();

      if (textItems.some((text) => text === undefined)) {
        return null;
      }
      if (hrefItems.some((href) => href === undefined)) {
        return null;
      }

      const pillItems = textItems.map((values: string, index: number) => ({
        href: hrefItems[index],
        text: values,
      }));

      return (
        <ExploreProducts
          title={title}
          pillItems={pillItems}
        />
      );
    } catch (error) {
      return null;
    }
  },

  shouldProcessNode: (node: ParsedHTMLElement) => node.attribs?.class?.includes('explore-our-products-widget'),
};
const readOurReviewsInstructions = {
  processNode: (node: ParsedHTMLElement, children: JSX.Element[]) => {
    try {
      if (!children) return null;
      const group: JSX.Element[] = children[0].props.children;
      const tabs: JSX.Element[] = group.find(
        (el) => typeof el === 'object' && (el.props.className as string).indexOf('wp-block-getwid-tabs') > -1,
      )?.props?.children;
      const tabsNav: JSX.Element[] = tabs.filter(
        (el) => typeof el === 'object' && el.type === 'div' && (el.props.className as string).indexOf('nav-link') > -1,
      );

      type JSXElementType = JSX.Element | JSX.Element[] | string;

      const removeTitleAndDescriptionWrapper = (element: JSX.Element): JSX.Element => {
        // remove title and description wrapper in order to properly place title and description in grid
        const titleAndDescriptionIndex: number = (element.props.children as JSX.Element[]).findIndex(
          (el: JSX.Element) =>
            el?.props?.className && (el.props.className as string).indexOf('title-and-description') > -1,
        );
        return {
          ...element,
          props: {
            ...element.props,
            children: [
              ...element.props.children,
              ...element.props.children[titleAndDescriptionIndex].props.children.props.children,
            ].filter((_, index) => index !== titleAndDescriptionIndex),
          },
        };
      };

      const getNavText = (element: JSXElementType): string => {
        if (!element || typeof element === 'string') {
          return element;
        }
        const newElement = Array.isArray(element) ? element[0] : element;
        if (React.isValidElement(newElement)) {
          return getNavText((newElement as JSX.Element).props.children as JSXElementType);
        }
        return '';
      };

      const tabsContent: JSX.Element[][] = tabs
        .filter(
          (el) =>
            typeof el === 'object' && el.type === 'div' && (el.props.className as string).indexOf('tab-content') > -1,
        )
        ?.map((content) => {
          const tabContent = content.props.children.props.children as JSX.Element | JSX.Element[];
          if (!Array.isArray(tabContent) && (tabContent.props.className as string).indexOf('review-card-widget') > -1) {
            return [removeTitleAndDescriptionWrapper(tabContent)];
          }
          if (Array.isArray(tabContent)) {
            return tabContent
              .filter(
                (element) =>
                  element?.props?.className && (element?.props?.className as string).indexOf('review-card-widget') > -1,
              )
              .map((element) => removeTitleAndDescriptionWrapper(element));
          }
          return [];
        });

      const cta: JSX.Element[] = group.find((el) => typeof el === 'object' && el.type === 'p')?.props?.children || null;
      const title = group.find((el) => typeof el === 'object' && el.type === 'h2') || null;

      const tabsConfig = [
        ...tabsNav.map((navItem, index) => ({
          children: tabsContent[index],
          title: getNavText(navItem),
        })),
      ];

      const autoPopulateAllTab = node?.attribs?.class?.match(/all-top-(\d+)/);
      if (autoPopulateAllTab) {
        const allTabCount = Math.min(parseInt(autoPopulateAllTab[1], 10), 20);
        const allTabContent = Array.from({ length: allTabCount })
          .map((_, i) => tabsContent.map((arr) => arr[i]).filter((item) => item))
          .flat()
          .slice(0, allTabCount);
        tabsConfig.unshift({
          children: allTabContent,
          title: 'All',
        });
      }

      return (
        <div className='read-our-reviews-widget'>
          {title}
          <Tabs
            config={tabsConfig}
            defaultIndex={0}
            dropdownMobile
          />
          {cta}
        </div>
      );
    } catch (err) {
      return null;
    }
  },
  shouldProcessNode: (node: ParsedHTMLElement) =>
    node.name === 'div' && node?.attribs?.class?.includes('read-our-reviews-widget'),
};

const deleteKeyTakeawaysInstructions = {
  processNode: () => {},
  shouldProcessNode: (node: ParsedHTMLElement) =>
    node.name === 'div' && node?.attribs?.class?.includes('key-takeaways-widget'),
};

const deleteTopSectionInstructions = {
  processNode: () => {},
  replaceChildren: false,
  shouldProcessNode: (node: ParsedHTMLElement) =>
    // @ts-ignore
    node.name === 'section' && node.attribs?.class?.includes('top-widgets-section'),
};

const defaultInstruction = {
  processNode: processNodeDefinitions.processDefaultNode,
  shouldProcessNode: () => true,
};

const getEduCtaLinksInstructions = (args: any) => ({
  processNode: (node: ParsedHTMLElement, children: ParsedHTMLElement[]) => {
    const { href, class: className, style, target, ...props } = node.attribs;

    // Append all other utm parameters
    const processedHref = setUtmParams(
      href,
      args.entityId, // eslint-disable-line @typescript-eslint/no-unsafe-argument
      args.utmCampaign, // eslint-disable-line @typescript-eslint/no-unsafe-argument
      args.position, // eslint-disable-line @typescript-eslint/no-unsafe-argument
    );
    const styleObj = style ? { style: parse(style) as CSSProperties } : {};
    return (
      <a
        href={processedHref || ''}
        aria-label={`Go to ${href}`}
        data-adtag={args.adTag}
        target={target}
        rel='noopener'
        className={className}
        {...styleObj}
        {...props}
      >
        {children}
      </a>
    );
  },
  replaceChildren: false,
  shouldProcessNode: (node: ParsedHTMLElement) => node.name === 'a',
});

const processingInstructions = [
  tableTagParentInstructions,
  videoParentTagInstructions,
  aTagInstruction,
  parentImgTagInstruction,
  imgTagInstruction,
  iframeTagInstruction,
  vodVideoDivInstruction,
  hrTagInstruction,
  newLineRemovalInstruction,
  twitterScriptInstructions,
  paywallDivInstructions,
  regwallDivInstructions,
  photoGalleryInstruction,
  styleScriptInstructions,
  productCardAccordionInstructions,
  detailsSummaryInstructions,
  responsiveTableInstructions,
  deleteKeyTakeawaysInstructions,
  defaultInstruction,
];

const RawHtml = ({ html }: Props) => {
  const HtmlToReactParser: ParserInstance = new HtmlToReact.Parser();
  return HtmlToReactParser.parseWithInstructions(html, () => true, processingInstructions);
};

export const ParseHtmlDefault = (html: string) => {
  const HtmlToReactParser: ParserInstance = new HtmlToReact.Parser();
  return HtmlToReactParser.parseWithInstructions(html, () => true, [
    imgTagInstruction,
    aTagInstruction,
    defaultInstruction,
  ]);
};

export const ParseArticleContent = (html: string, removeTopContent?: boolean) => {
  const HtmlToReactParser: ParserInstance = new HtmlToReact.Parser();
  const removeTopContentInstructions = [
    ...processingInstructions.slice(0, -1),
    deleteTopSectionInstructions,
    defaultInstruction,
  ];
  return HtmlToReactParser.parseWithInstructions(
    html,
    () => true,
    removeTopContent ? removeTopContentInstructions : processingInstructions,
  );
};

export const ParseReadOurReview = (html: string) => {
  const HtmlToReactParser: ParserInstance = new HtmlToReact.Parser();
  return HtmlToReactParser.parseWithInstructions(html, () => true, [
    aTagInstruction,
    imgTagInstruction,
    readOurReviewsInstructions,
    defaultInstruction,
  ]);
};

export const ParseExploreOurProducts = (html: string) => {
  const HtmlToReactParser: ParserInstance = new HtmlToReact.Parser();
  return HtmlToReactParser.parseWithInstructions(html, () => true, [
    aTagInstruction,
    exploreOurProductsInstructions,
    defaultInstruction,
  ]);
};

export const ParseEduAdWidgets = (html: string, args: any) => {
  const HtmlToReactParser: ParserInstance = new HtmlToReact.Parser();
  return HtmlToReactParser.parseWithInstructions(html, () => true, [
    getEduCtaLinksInstructions(args),
    defaultInstruction,
  ]);
};

export default RawHtml;
