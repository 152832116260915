import { useState, useRef, KeyboardEvent } from 'react';
import Dropdown from 'components/Globals/Base/Dropdown';
import * as S from './TabsStyles';

export interface TabsProps {
  config: {
    title: string;
    children: React.ReactNode;
  }[];
  defaultIndex: number;
  className?: string;
  onClick?: (value: number) => void;
  color?: string;
  variant?: 'default' | 'boxed' | 'buttons';
  enableTabsSlider?: boolean;
  tabDataCy?: string;
  contentDataCy?: string;
  dropdownMobile?: boolean;
  hideArrowOnDesktop?: boolean;
}

const Tabs = ({
  config,
  onClick,
  defaultIndex,
  className = '',
  color,
  variant = 'default',
  enableTabsSlider = false,
  tabDataCy = '',
  contentDataCy = '',
  dropdownMobile = false,
  hideArrowOnDesktop = false,
}: TabsProps) => {
  const [selectedIndex, setSelectedIndex] = useState(defaultIndex ?? 0);
  const [prevDisabledClass, setPrevDisabledClass] = useState(true);
  const [nextDisabledClass, setNextDisabledClass] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const buttonsRef = useRef<{ [key: number]: HTMLButtonElement | null }>({});
  let nextClickCounter = 0;
  const scrollValue = 200;

  const changeTab = (index: number) => {
    setSelectedIndex(index);
    if (typeof onClick === 'function') {
      onClick(index);
    }
  };

  const setIndex = (index: number) => {
    const tabButton = buttonsRef?.current[index];
    if (tabButton) {
      tabButton.focus();
      setSelectedIndex(index);
      if (typeof onClick === 'function') onClick(index);
    }
  };

  const onKeyDown = (event: KeyboardEvent) => {
    const count = config.length;
    const nextTab = () => setIndex((selectedIndex + 1) % count);
    const prevTab = () => setIndex((selectedIndex - 1 + count) % count);
    const firstTab = () => setIndex(0);
    const lastTab = () => setIndex(count - 1);

    const keyMap = {
      ArrowLeft: prevTab,
      ArrowRight: nextTab,
      End: lastTab,
      Home: firstTab,
    } as { [key: string]: () => void };

    const action = keyMap[event.key];

    if (action) {
      event.preventDefault();
      action();
    }
  };

  const onNextArrowClick = () => {
    if (wrapperRef.current) {
      setPrevDisabledClass(false);
      const tabsWidth = wrapperRef.current.scrollWidth;
      const tabsVisibleWidth = wrapperRef.current.offsetWidth;
      let widthToScroll = tabsWidth - tabsVisibleWidth;
      wrapperRef.current.scrollLeft += scrollValue;
      nextClickCounter += 1;
      widthToScroll -= scrollValue;

      if (widthToScroll < nextClickCounter * scrollValue) {
        setNextDisabledClass(true);
      }
    }
  };

  const onPrevArrowClick = () => {
    if (wrapperRef.current) {
      wrapperRef.current.scrollLeft -= scrollValue;
      setNextDisabledClass(false);

      if (wrapperRef.current.scrollLeft <= scrollValue) {
        setPrevDisabledClass(true);
      }
    }
  };

  if (config.length === 0) return null;

  return (
    <S.Wrapper
      role='tablist'
      aria-label='Tab List'
      data-cy={tabDataCy}
      className={`tabs-wrapper ${className}`}
      $variant={variant}
      $color={color}
      $dropdownMobile={dropdownMobile}
    >
      <S.Header className='tab-controls'>
        {enableTabsSlider && (
          <S.ArrowButton
            onClick={onPrevArrowClick}
            role='button'
            className={`prev ${prevDisabledClass ? 'disabled' : ''} ${hideArrowOnDesktop ? 'hide' : ''}`}
            aria-label='Previous Button for Tab List'
          >
            <span className='arrow' />
          </S.ArrowButton>
        )}
        <S.ButtonsWrapper ref={wrapperRef}>
          {config.map(({ title }, index) => (
            <S.Button
              key={title}
              className={className}
              onMouseDown={() => changeTab(index)}
              onFocus={() => setSelectedIndex(index)}
              onKeyDown={(event) => onKeyDown(event)}
              aria-selected={index === selectedIndex}
              role='tab'
              data-cy={`${tabDataCy}-${index}`}
              id={`tab-${index}`}
              tabIndex={selectedIndex === index ? 0 : -1}
              aria-controls={`tabpanel-${index}`}
              ref={(element) => {
                buttonsRef.current[index] = element;
              }}
            >
              {title}
            </S.Button>
          ))}
        </S.ButtonsWrapper>
        {enableTabsSlider && (
          <S.ArrowButton
            onClick={onNextArrowClick}
            role='button'
            className={`next ${nextDisabledClass ? 'disabled' : ''} ${hideArrowOnDesktop ? 'hide' : ''}`}
            aria-label='Next Button for Tab List'
          >
            <span className='arrow' />
          </S.ArrowButton>
        )}
      </S.Header>
      <div className='dropdown-controls'>
        <Dropdown
          list={config.map((configVal) => ({ label: configVal.title, value: configVal.title }))}
          selected={config.find((_, index) => index === selectedIndex)?.title}
          onOptionClick={(_, index) => {
            changeTab(index);
          }}
          size='small'
        />
      </div>
      {config.map(({ children }, index) => (
        <S.Panel
          // eslint-disable-next-line react/no-array-index-key
          key={`panel-${index}`}
          id={`tabpanel-${index}`}
          data-cy={`${contentDataCy}-${index}`}
          role='tabpanel'
          tabIndex={0}
          aria-labelledby={`tab-${index}`}
          className={index === selectedIndex ? '' : 'is-hidden'}
        >
          {children}
        </S.Panel>
      ))}
    </S.Wrapper>
  );
};

export default Tabs;
